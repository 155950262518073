.action {

    &-line {

        &__body {
            @apply py-8;

            @include media-breakpoint-up(lg) {
                @apply py-14;

                &::before {
                    content: '';
                    @apply absolute top-4 left-1/2 h-full bg-primary -translate-x-1/2;
                    width: 2px;
                }
    
                &::after {
                    content: '';
                    @apply absolute -bottom-4 right-0 left-0 mx-auto bg-primary;
                    width: 125px;
                    height: 2px;
                }
            }
        }

        &__row {
            @apply grid gap-4;

            @include media-breakpoint-up(lg) {
                @apply gap-0;
            }

            &-column {

                .media {

                    @include media-breakpoint-up(lg) {

                        &::before {
                            content: '';
                            @apply absolute top-1/2 left-0 w-full -translate-y-1/2 bg-warning;
                            height: 1px;
                        }

                        &-dialog {
    
                            &::after {
                                content: '';
                                @apply absolute -right-[10px] top-1/2 w-5 h-5 -translate-y-1/2 rounded-full bg-warning;
                            }
                        }
                    }
                }

                &:nth-of-type(even) {
                    
                    .media {
                        @apply ml-auto text-right;

                        &-dialog {
                            @apply text-left;

                            @include media-breakpoint-up(lg) {

                                &::after {
                                    @apply -left-[10px] right-[unset];
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}