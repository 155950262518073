.jumbotron {
    height: 175px;

    @include media-breakpoint-up(lg) {
        height: 300px;
    }

    &-image {
        @apply w-1/2 h-full ml-auto;

        .jumbotron--about & {
            @apply bg-primary prose-img:opacity-60;
        }
    }

    &-body {
        @apply absolute top-0 right-0 left-0 bg-no-repeat bg-left bg-cover;
        background: linear-gradient(90deg, rgba(37,43,91,1) 50%, rgba(0,0,0,0) 100%);

        .jumbotron--page & {
            @apply bg-primary;
        }
    }

    &-title {

        @include media-breakpoint-up(lg) {
            @apply prose-headings:text-4xl;
            width: 400px;
        }

        .jumbotron--page & {
            @apply w-full;
        }
    }
}