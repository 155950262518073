.footer {

    &::before {
        content: '';
        @apply bg-primary absolute w-full;
        height: 200px;
        mask-image: url(../images/wave.png);
        mask-position: center top;
        margin-top: -100px;
        mask-repeat: no-repeat;
        mask-size: cover;
    }

    &-main {
        @apply pt-12 pb-10;

        @include media-breakpoint-up(lg) {
            @apply pb-16;
        }
    }

    &-list {

        &__column {
            @apply prose-h5:mb-3;

            @include media-breakpoint-up(lg) {
                @apply prose-h5:mb-6;
            }
        }
    }

    &-social {
        @apply mb-10;

        @include media-breakpoint-up(lg) {
            @apply flex items-center;

            &__list {
                @apply gap-12 ml-auto;

                &-item {
                    @apply gap-12;
                }
            }
        }

        &__brand {
            
            @include media-breakpoint-down(sm) {
                @apply mb-4;
            }
        }

        &__list {
            @apply flex items-center gap-4;

            &-item {
                @apply gap-4;
            }
        }
    }

    &-address {

        &__item {
            @apply prose-p:flex-1;

            .icon {
                @apply w-8 prose-img:w-4;
                margin-top: 6px;
            }
        }
    }

    &-copy {

        @include media-breakpoint-down(lg) {
            @apply text-center;
        }
    }
}