.pagination {

    &-item {

        &__button {
            @apply font-bold;
            line-height: 24px;

            &.disabled {
                @apply cursor-not-allowed opacity-50;
            }

            &.current {
                @apply text-primary text-2xl;
            }
        }
    }
}