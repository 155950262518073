$root-variables: (
    // base color
    base-color: #000,
    white-color: #fff,
    // navbar
    navbar-top-height: 100px,
    navbar-main-height: 64px,

    // loading regular
    loading-size: 1.25rem,

    // loading small
    loading-sm-size: 1rem,

    // loading large
    loading-lg-size: 1.5rem,

    // loading extra large
    loading-xl-size: 2rem,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1080px,
    xl: 1200px,
    xxl: 1400px
);

:root {
    @each $key, $value in $root-variables {
        --#{$key}: #{$value};
    }
}
  