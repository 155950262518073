.cold {

    &-point {

        @include media-breakpoint-up(lg) {
            padding-bottom: 175px;
        }
    }

    &-store {

        &__title {

            @include media-breakpoint-up(lg) {
                @apply prose-headings:text-6xl;
            }
        }
    }

    &-flow {

        &__title {

            &-text {
                @apply prose-headings:mb-4 prose-headings:text-primary;

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-6xl;
                }
            }

            &-list {
                counter-reset: item-counter;

                .item {

                    &-marker {
                        @apply flex items-center border-b border-b-[#EFEFEF];
                        
                        &::before {
                            @apply inline-flex items-center justify-center mr-4 font-bold text-primary bg-[#EFEFEF];
                            counter-increment: item-counter;
                            content: counter(item-counter);

                            @include media-breakpoint-up(lg) {
                                @apply w-14 h-14 text-4xl;
                            }
                        }
                    }
                }
            }
        }

        &__thumb {
            @apply lg:absolute 3xl:relative 3xl:w-full #{!important};

            @include media-breakpoint-up(lg) {
                @apply bottom-16 right-0;
                width: calc(50% - 40px);

                img {
                    @apply w-full;
                }
            }
        }
    }

    &-app {

        &__row {

            @include media-breakpoint-up(lg) {
                @apply px-16 py-12;
            }
        }

        &__title {
            @apply flex-1;

            @include media-breakpoint-up(lg) {
                @apply w-2/3 pr-10;

                &-text {
                    @apply prose-headings:text-3xl;
                }
            }
        }

        &__thumb {

            @include media-breakpoint-up(lg) {
                @apply absolute right-0 bottom-0;
            }
        }
    }
}