.accordion {

    &-item {
        @apply bg-white;
        box-shadow: 0px 4px 24px rgba($color: #000000, $alpha: .1);
    }

    &-toggle {

        &[aria-expanded=true] {
            background-color: inherit;
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            .icon {

                svg {
                    @apply rotate-180;
                }
            }
        }

        &[aria-expanded=false] {
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .title {
            @apply flex-1 pr-4;
        }

        .icon {

            svg {
                @apply h-4 fill-primary;
                transition-duration: .5s;
                transition-property: transform;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }

    &-content {
        transition-duration: .5s;
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &[aria-hidden=true] {
            height: 0;
            opacity: 0;
            transform: translate3d(0, -0.5rem, 0);
            visibility: hidden;
        }

        &[aria-hidden=false] {
            height: auto;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
    }
}