.form {

    &-input, &-textarea, &-select {
        @apply text-black border-gray w-full px-4 py-3 transition ease-in-out ring-0 placeholder:text-gray focus:border-secondary #{!important};

        &[disabled] {
            @apply bg-gray;
        }

        .career-search__form & {
            @apply placeholder:text-black #{!important};
        }
    }

    &-validate {

        .form {

            &-input, &-textarea, &-select {
                @apply border-primary #{!important};
            }
        }

        &__feedback {
            @apply mt-1 text-primary;

            small {
                @apply text-sm;
            }
        }
    }
}