.navbar {
    @apply relative;
    transition: ease-in-out .25s, height .25s;

    @include media-breakpoint-down(lg) {
        @apply top-4;
    }

    &--fixed {
        @apply fixed top-4;

        .navbar {

            &-front {
                @apply hidden;
            }

            &-back {
                @apply block;
            }
        }
    }

    &-front {
        @apply opacity-100 visible;
        transition: opacity 0.5s ease, visibility 0.5s ease;

        @include media-breakpoint-down(lg) {
            @apply hidden;
        }
    }

    &-back {
        transition: opacity 0.5s ease, visibility 0.5s ease;

        @include media-breakpoint-only(md) {
            max-width: 100% #{!important};
        }

        @include media-breakpoint-up(lg) {
            @apply hidden;
        }
    }

    &-dialog {
        
        .navbar-back & {
            @apply bg-white px-4;
            box-shadow: 0 8px 16px rgba($color: #000000, $alpha: .1);
        }
    }

    &-top {
        height: var(--navbar-top-height);
    }

    &-main {
        height: var(--navbar-main-height);

        &::before {
            content: '';
            @apply bg-primary bg-opacity-60 absolute top-4 right-8 left-8 h-full;
            filter: blur(16px);
        }
    }

    &-logo {
        @apply prose-img:h-16;

        @include media-breakpoint-up(lg) {
            @apply prose-img:h-22;
            margin-right: auto;
        }
    }

    &-menu {
        @apply hidden lg:flex;

        &__list {
            @apply h-full;

            .active {

                > a {

                    > svg {
                        @apply fill-secondary;
                    }
                }
            }

            &-item {
                @apply flex items-center h-full border-b-2 border-b-transparent;

                @include media-breakpoint-up(lg) {
                    height: var(--navbar-main-height);
                
                    .navbar-back & {
                        height: 88px;
                    }
                }
            }
        }
    }

    &-social {

        .navbar-back & {

            @include media-breakpoint-down(lg) {
                @apply hidden;
            }
        }

        &__item {

            > p {
                @apply text-xs;
                margin-left: calc(20px + 8px);
            }

            &-link {
                @apply inline-flex items-center text-primary lg:text-1xl font-bold transition ease-in-out;
                user-select: none;

                &:hover {
                    @apply text-warning;
                }
            }
        }
    }
}