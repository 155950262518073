.services {

    &-jumbotron {

        &__list {

            .item {

                &:nth-of-type(odd) {

                    .item {

                        &-image {
                                
                            img {
                                @apply rounded-tl-[200px];
                            }
                        }
                    }
                }

                &:nth-of-type(even) {

                    .item {

                        &-image {
                                
                            img {
                                @apply rounded-tr-[200px];
                            }
                        }
                    }
                }

                @include media-breakpoint-up(lg) {

                    &:nth-of-type(even) {

                        .item {

                            &-content {
                                @apply order-2;
                            }
                        }
                    }
                }

                &-content {
                    @apply prose-headings:text-primary;

                    @include media-breakpoint-up(lg) {
                        @apply w-[55%] #{!important};

                        &__title {
                            @apply mb-4 prose-headings:text-4xl;
                        }
                    }
                }

                &-image {

                    @include media-breakpoint-up(lg) {
                        @apply w-[45%] #{!important};
                    }
                }
            }
        }
    }

    &-video {

        &__dialog {
            @apply relative aspect-[8/3.5];

            @include media-breakpoint-up(lg) {

                &::before {
                    content: '';
                    @apply absolute top-0 right-0 left-0 bottom-0 bg-black bg-opacity-60;
                }
            }
        }

        &__content {

            @include media-breakpoint-down(lg) {
                @apply py-8;
            }

            &-text {

                .title {
                    @apply mb-8;
                }

                .point {

                    &-list {
                        @apply mb-8;
                    }
                }

                @include media-breakpoint-up(lg) {
                    
                    .title {
                        @apply mb-14 prose-headings:text-5xl prose-headings:mx-10;
                    }

                    .point {
                        
                        &-list {
                            @apply mb-14;
                        }
                    }
                }

                .point {

                    &-list {

                        &__item {

                            &-content {
                                @apply mt-2;

                                @include media-breakpoint-up(lg) {
                                    @apply prose-headings:mb-2 prose-headings:text-6xl prose-headings:italic prose-p:text-1xl prose-p:font-bold;
                                }
                            }
                        }
                    }

                    &-action {

                        .btn {
                            @apply border-primary;

                            &:hover {
                                @apply bg-primary text-white;
                            }

                            @include media-breakpoint-up(lg) {
                                @apply border-white;

                                &:hover {
                                    @apply bg-white text-primary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}