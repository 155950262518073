.services {

    &-problem {

        &__solution {
            @apply mt-10 py-10;

            @include media-breakpoint-up(lg) {
                @apply mt-16 py-16;
            }

            &-list {

                .item {
                    @apply relative;
                    padding-bottom: 64px;

                    @include media-breakpoint-up(lg) {
                        padding-bottom: 64px + 32px + 32px;

                        &::before {
                            content: '';
                            @apply bg-primary absolute bottom-8 left-1/2 h-16 -translate-x-1/2;
                            width: 1px;
                        }
                    }

                    &:last-child {
                        @apply pb-0;

                        &::before {
                            @apply hidden;
                        }
                    }
                }
            }
        }

        &__description {
            @apply mb-10;
            
            @include media-breakpoint-up(lg) {
                @apply mb-16;
            }
        }
    }

    &-testi {

        &__list {

            .item {

                &-image {
                    height: 215px;
                }

                &-content {
                    @apply absolute bottom-4 left-4 right-4 prose-headings:text-primary;
                }
            }
        }
    }

    &-sub {

        &__hero {

            &-content {

                .title {

                    @include media-breakpoint-up(lg) {
                        @apply prose-headings:text-primary prose-headings:text-8xl;
                    }
                }
            }
        }

        &__experience {

            &-title {
                @apply mb-8;

                @include media-breakpoint-up(lg) {
                    @apply flex items-end mb-14 prose-headings:text-8xl;

                    .actions {
                        @apply pl-20 prose-p:text-1xl;
                    }
                }
            }

            &-point {

                .item {
                    @apply w-full;

                    @include media-breakpoint-up(lg) {
                        @apply w-1/3;

                        &-title {
                            @apply mb-8;
                        }
                    }

                    &-title {
                        @apply relative;

                        &::before {
                            content: '';
                            @apply absolute -bottom-4 bg-warning w-18;
                            height: 2px;
                        }
                    }
                }
            }
        }

        &__case {

            .item {

                &:nth-of-type(even) {

                    .item {

                        &-image {
                            @apply rounded-tr-none rounded-tl-[200px] order-2;
                        }
                    }
                }

                &-image {
                    @apply rounded-tr-[200px] overflow-hidden prose-img:w-full;
                }

                &-content {

                    &__title {
                        @apply mb-5;
                    }
                }
            }
        }
    }
}