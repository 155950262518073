.mobility {

    &-point {

        &__title {
            @apply mb-8 prose-headings:text-primary;

            @include media-breakpoint-up(lg) {
                @apply mb-14 prose-headings:text-8xl;
            }
        }
    }

    &-flow {

        &__title {
            @apply prose-headings:mb-6;

            @include media-breakpoint-up(lg) {
                @apply prose-headings:text-8xl;
            }
        }
    }

    &-maps {

        &__row {

            .left {

                @include media-breakpoint-down(md) {
                    @apply order-2;
                }

                &-list {
                    @apply overflow-y-auto;
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                    height: 400px;

                    @include media-breakpoint-up(lg) {
                        @apply w-2/5 mr-8;
                        height: 650px;
                    }

                    &::-webkit-scrollbar {
                        width: 0;
                        background: transparent;
                    }

                    .media {
                        @apply mb-6 pb-6 border-b border-b-grayMedium;
                    }
                }
            }
            
            .right {

                @include media-breakpoint-down(md) {
                    @apply mb-8 order-1;
                }

                @include media-breakpoint-up(lg) {
                    @apply absolute top-0 right-0 w-[55%];
                    height: 650px;
                }
            }
        }
    }
}