$slick-loader-path: "/assets/js/vendor/slick/" !default;
$slick-font-path: "/assets/js/vendor/slick/fonts/" !default;

@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.slick {

    &--banner {
        @apply mb-0 #{!important};
    }

    &-list {

        .slick--choose & {
            overflow: unset;
        }
    }

    &-track {

        .slick--miles-thumb &, .slick--choose & {
            @apply flex;
        }
    }

    &-slide {

        .slick--client & {
            @apply mx-3;

            @include media-breakpoint-up(lg) {
                @apply mx-6;
            }

            .item {
                @apply prose-img:h-8;

                @include media-breakpoint-up(lg) {
                    @apply prose-img:h-14;
                }

                &-content {
                    @apply mt-6 prose-headings:mb-2 prose-headings:text-rg;
                }
            }
        }

        .slick--banner & {

            .item {
                @apply relative aspect-square overflow-hidden;

                @include media-breakpoint-up(md) {
                    aspect-ratio: 8/3.25;
                }

                &.with-text {
                    @apply prose-headings:text-2xl prose-headings:mb-4 prose-headings:mr-8 prose-headings:text-primary;

                    @include media-breakpoint-up(lg) {
                        @apply prose-headings:text-8xl;
                    }

                    .item {

                        @include media-breakpoint-only(md) {

                            &-dialog, &-row {
                                @apply h-full;
                            }
                        }

                        &-content, &-image {
                            @apply w-full px-4;
                        }

                        &-content {

                            @include media-breakpoint-only(md) {
                                @apply w-3/5;
                            }
                            
                            @include media-breakpoint-up(lg) {
                                @apply w-2/5 pt-24;
                            }

                            .detail {

                                &-actions {
                                    @apply mt-4;

                                    @include media-breakpoint-up(lg) {
                                        @apply mt-8;
                                    }
                                }
                            }
                        }

                        &-image {
                            @apply prose-img:w-full;

                            @include media-breakpoint-only(md) {
                                @apply w-2/5;
                            }

                            @include media-breakpoint-up(lg) {
                                @apply absolute top-0 -right-4 w-3/5;
                            }
                        }
                    }
                }

                &.with-image {

                    .item {

                        &-image {
                            @apply aspect-square overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;

                            @include media-breakpoint-up(md) {
                                aspect-ratio: 8/3.25;
                            }
                        }
                    }
                }
            }
        }

        .slick--miles-parent & {
            @apply mx-5;
        }

        .slick--miles-thumb & {
            @apply mx-2;
            height: inherit;

            > div {
                @apply h-full;

                > div {
                    @apply h-full;
                }
            }

            .item {
                @apply relative flex items-center h-full px-4 pb-3 border-l-2 border-l-[#AEAEAE];

                &::before {
                    content: '';
                    @apply absolute top-0 -left-2 w-4 h-4 bg-[#AEAEAE] rounded-full overflow-hidden;
                }

                &-image {
                    @apply w-16 h-16 rounded-full overflow-hidden prose-img:grayscale;
                }

                &-content {
                    @apply flex-1 pl-2;

                    &__title {
                        @apply prose-headings:text-rg;
                    }

                    &__post {
                        @apply prose-p:line-clamp-2;
                    }
                }
            }
        }

        .slick--services & {
            @apply mx-4;

            .item {

                &-image {
                    @apply overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
                    aspect-ratio: 8/4;
                }
            }
        }

        .slick--choose & {
            @apply mx-2;
            height: inherit;

            @include media-breakpoint-up(lg) {
                @apply mx-4;
            }

            > div {
                @apply h-full;

                > div {
                    @apply h-full;
                }
            }

            .item {
                @apply block bg-white h-full p-4 overflow-hidden;

                @include media-breakpoint-up(lg) {
                    @apply py-10 px-12;
                }

                &-image {
                    @apply aspect-video overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
                }

                &-content {

                    &__title {
                        @apply mb-4 text-primary;
                    }
                }
            }
        }

        .slick--choose__mobility & {

            @include media-breakpoint-up(lg) {
                @apply mx-5;
            }

            .item {
                @apply bg-transparent p-0;

                &-content {

                    &__text {
                        @apply prose-p:line-clamp-3 prose-p:h-18;
                    }
                }
            }
        }

        &.slick-current {

            .slick--miles-thumb & {

                .item {
                    @apply border-l-secondary;

                    &::before {
                        @apply bg-secondary;
                    }
    
                    &-image {
                        @apply prose-img:grayscale-0;
                    }
                }
            }
        }
    }

    &-dots {
        
        .slick--banner & {
            @apply -bottom-6;

            @include media-breakpoint-up(lg) {
                bottom: calc(96px + 32px);
            }
        }

        > li {
            @apply w-2 h-2 mx-[2px];

            button {
                @apply w-2 h-2 rounded-full before:hidden;
                background-color: #ABABAB;
            }

            &.slick-active {
                @apply w-10;
                
                button {
                    @apply bg-secondary w-10;
                }
            }
        }
    }

    &-right, &-left {
        @apply absolute bg-white w-10 h-10 lg:w-12 lg:h-12 border border-primary cursor-pointer z-10;

        &:hover {
            @apply bg-warning border-warning;

            svg {
                @apply fill-white;
            }
        }
    
        &.slick-disabled {
            @apply cursor-not-allowed;
        }

        .slick--client & {
            top: -(calc(32px + 32px));

            @include media-breakpoint-up(lg) {
                top: -(calc(32px + 48px));
            }

            &.slick-disabled {
                @apply opacity-50;
            }
        }

        .slick--services & {
            @apply top-1/2 -translate-y-1/2;
        }
    }

    &-right {
        
        .slick--client & {
            @apply right-0;
        }

        .slick--services & {
            @apply -right-2;
        }
    }

    &-left {
        
        .slick--client & {
            @apply left-0;
        }

        .slick--services & {
            @apply -left-2;
        }
    }

    &-pagination {

        &__prev, &__next {
            @apply w-12 h-12 bg-white border border-primary cursor-pointer;

            &:hover {
                @apply bg-warning border-warning;

                svg {
                    @apply fill-white;
                }
            }
        }
    }
}