.contact {

    &-front {

        &__maps {

            @include media-breakpoint-up(lg) {
                height: 600px;
            }

            &::before, &::after {
                content: '';
                @apply block;
            }

            &::before {
                padding-top: 50%;
            }

            &::after {
                @apply clear-both;
            }

            > * {
                @apply absolute top-0 left-0 h-full w-full;
            }
        }

        &__list {

            @include media-breakpoint-up(lg) {
                margin-top: -12.5%;
            }
        }
    }
}