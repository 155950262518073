.breadcrumb {
    @apply mb-5;

    &-item {

        + .breadcrumb {

            &-item {
                @apply pl-1;
                
                &::before {
                    @apply inline-block pr-1 float-left text-white;
                    content: "/";
                }
            }
        }

        &.active {
            @apply text-secondary;
        }
    }
}