@charset "UTF-8";
:root {
  --base-color: #000;
  --white-color: #fff;
  --navbar-top-height: 100px;
  --navbar-main-height: 64px;
  --loading-size: 1.25rem;
  --loading-sm-size: 1rem;
  --loading-lg-size: 1.5rem;
  --loading-xl-size: 2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 1080px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1079.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1080px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/assets/js/vendor/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/assets/js/vendor/slick/fonts/slick.eot");
  src: url("/assets/js/vendor/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/assets/js/vendor/slick/fonts/slick.woff") format("woff"), url("/assets/js/vendor/slick/fonts/slick.ttf") format("truetype"), url("/assets/js/vendor/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.slick--banner {
  @apply mb-0 !important;
}
.slick--choose .slick-list {
  overflow: unset;
}
.slick--miles-thumb .slick-track, .slick--choose .slick-track {
  @apply flex;
}
.slick--client .slick-slide {
  @apply mx-3;
}
@media (min-width: 1080px) {
  .slick--client .slick-slide {
    @apply mx-6;
  }
}
.slick--client .slick-slide .item {
  @apply prose-img:h-8;
}
@media (min-width: 1080px) {
  .slick--client .slick-slide .item {
    @apply prose-img:h-14;
  }
}
.slick--client .slick-slide .item-content {
  @apply mt-6 prose-headings:mb-2 prose-headings:text-rg;
}
.slick--banner .slick-slide .item {
  @apply relative aspect-square overflow-hidden;
}
@media (min-width: 768px) {
  .slick--banner .slick-slide .item {
    aspect-ratio: 8/3.25;
  }
}
.slick--banner .slick-slide .item.with-text {
  @apply prose-headings:text-2xl prose-headings:mb-4 prose-headings:mr-8 prose-headings:text-primary;
}
@media (min-width: 1080px) {
  .slick--banner .slick-slide .item.with-text {
    @apply prose-headings:text-8xl;
  }
}
@media (min-width: 768px) and (max-width: 1079.98px) {
  .slick--banner .slick-slide .item.with-text .item-dialog, .slick--banner .slick-slide .item.with-text .item-row {
    @apply h-full;
  }
}
.slick--banner .slick-slide .item.with-text .item-content, .slick--banner .slick-slide .item.with-text .item-image {
  @apply w-full px-4;
}
@media (min-width: 768px) and (max-width: 1079.98px) {
  .slick--banner .slick-slide .item.with-text .item-content {
    @apply w-3/5;
  }
}
@media (min-width: 1080px) {
  .slick--banner .slick-slide .item.with-text .item-content {
    @apply w-2/5 pt-24;
  }
}
.slick--banner .slick-slide .item.with-text .item-content .detail-actions {
  @apply mt-4;
}
@media (min-width: 1080px) {
  .slick--banner .slick-slide .item.with-text .item-content .detail-actions {
    @apply mt-8;
  }
}
.slick--banner .slick-slide .item.with-text .item-image {
  @apply prose-img:w-full;
}
@media (min-width: 768px) and (max-width: 1079.98px) {
  .slick--banner .slick-slide .item.with-text .item-image {
    @apply w-2/5;
  }
}
@media (min-width: 1080px) {
  .slick--banner .slick-slide .item.with-text .item-image {
    @apply absolute top-0 -right-4 w-3/5;
  }
}
.slick--banner .slick-slide .item.with-image .item-image {
  @apply aspect-square overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
@media (min-width: 768px) {
  .slick--banner .slick-slide .item.with-image .item-image {
    aspect-ratio: 8/3.25;
  }
}
.slick--miles-parent .slick-slide {
  @apply mx-5;
}
.slick--miles-thumb .slick-slide {
  @apply mx-2;
  height: inherit;
}
.slick--miles-thumb .slick-slide > div {
  @apply h-full;
}
.slick--miles-thumb .slick-slide > div > div {
  @apply h-full;
}
.slick--miles-thumb .slick-slide .item {
  @apply relative flex items-center h-full px-4 pb-3 border-l-2 border-l-[#AEAEAE];
}
.slick--miles-thumb .slick-slide .item::before {
  content: "";
  @apply absolute top-0 -left-2 w-4 h-4 bg-[#AEAEAE] rounded-full overflow-hidden;
}
.slick--miles-thumb .slick-slide .item-image {
  @apply w-16 h-16 rounded-full overflow-hidden prose-img:grayscale;
}
.slick--miles-thumb .slick-slide .item-content {
  @apply flex-1 pl-2;
}
.slick--miles-thumb .slick-slide .item-content__title {
  @apply prose-headings:text-rg;
}
.slick--miles-thumb .slick-slide .item-content__post {
  @apply prose-p:line-clamp-2;
}
.slick--services .slick-slide {
  @apply mx-4;
}
.slick--services .slick-slide .item-image {
  @apply overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
  aspect-ratio: 8/4;
}
.slick--choose .slick-slide {
  @apply mx-2;
  height: inherit;
}
@media (min-width: 1080px) {
  .slick--choose .slick-slide {
    @apply mx-4;
  }
}
.slick--choose .slick-slide > div {
  @apply h-full;
}
.slick--choose .slick-slide > div > div {
  @apply h-full;
}
.slick--choose .slick-slide .item {
  @apply block bg-white h-full p-4 overflow-hidden;
}
@media (min-width: 1080px) {
  .slick--choose .slick-slide .item {
    @apply py-10 px-12;
  }
}
.slick--choose .slick-slide .item-image {
  @apply aspect-video overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
.slick--choose .slick-slide .item-content__title {
  @apply mb-4 text-primary;
}
@media (min-width: 1080px) {
  .slick--choose__mobility .slick-slide {
    @apply mx-5;
  }
}
.slick--choose__mobility .slick-slide .item {
  @apply bg-transparent p-0;
}
.slick--choose__mobility .slick-slide .item-content__text {
  @apply prose-p:line-clamp-3 prose-p:h-18;
}
.slick--miles-thumb .slick-slide.slick-current .item {
  @apply border-l-secondary;
}
.slick--miles-thumb .slick-slide.slick-current .item::before {
  @apply bg-secondary;
}
.slick--miles-thumb .slick-slide.slick-current .item-image {
  @apply prose-img:grayscale-0;
}
.slick--banner .slick-dots {
  @apply -bottom-6;
}
@media (min-width: 1080px) {
  .slick--banner .slick-dots {
    bottom: 128px;
  }
}
.slick-dots > li {
  @apply w-2 h-2 mx-[2px];
}
.slick-dots > li button {
  @apply w-2 h-2 rounded-full before:hidden;
  background-color: #ABABAB;
}
.slick-dots > li.slick-active {
  @apply w-10;
}
.slick-dots > li.slick-active button {
  @apply bg-secondary w-10;
}
.slick-right, .slick-left {
  @apply absolute bg-white w-10 h-10 lg:w-12 lg:h-12 border border-primary cursor-pointer z-10;
}
.slick-right:hover, .slick-left:hover {
  @apply bg-warning border-warning;
}
.slick-right:hover svg, .slick-left:hover svg {
  @apply fill-white;
}
.slick-right.slick-disabled, .slick-left.slick-disabled {
  @apply cursor-not-allowed;
}
.slick--client .slick-right, .slick--client .slick-left {
  top: -64px;
}
@media (min-width: 1080px) {
  .slick--client .slick-right, .slick--client .slick-left {
    top: -80px;
  }
}
.slick--client .slick-right.slick-disabled, .slick--client .slick-left.slick-disabled {
  @apply opacity-50;
}
.slick--services .slick-right, .slick--services .slick-left {
  @apply top-1/2 -translate-y-1/2;
}
.slick--client .slick-right {
  @apply right-0;
}
.slick--services .slick-right {
  @apply -right-2;
}
.slick--client .slick-left {
  @apply left-0;
}
.slick--services .slick-left {
  @apply -left-2;
}
.slick-pagination__prev, .slick-pagination__next {
  @apply w-12 h-12 bg-white border border-primary cursor-pointer;
}
.slick-pagination__prev:hover, .slick-pagination__next:hover {
  @apply bg-warning border-warning;
}
.slick-pagination__prev:hover svg, .slick-pagination__next:hover svg {
  @apply fill-white;
}

.btn {
  --primary-color: #252B5B;
  --primary-color-invert: white;
  --primary-color-hover: #ECCE18;
  --secondary-color: #C0942F;
  --secondary-color-invert: white;
  --secondary-color-hover: #ECCE18;
  --light-color: #fff;
  --light-color-invert: black;
  --light-color-hover: #ECCE18;
  --button-padding-y: 16px;
  --button-padding-x: 40px;
  --button-font-size: 1rem;
  --button-rounded: 0;
  --button-sm-padding-y: 4px;
  --button-sm-padding-x: 8px;
  --button-sm-font-size: 0.875rem;
  --button-lg-padding-y: 12px;
  --button-lg-padding-x: 16px;
  --button-lg-font-size: 1.25rem;
  --button-forms-padding-y: 12px;
  --button-forms-padding-x: 32px;
  --button-forms-font-size: 1em;
  @apply relative inline-flex items-center justify-center text-center px-4 py-3 border border-transparent font-bold;
  font-size: var(--button-font-size);
  border-radius: var(--button-rounded);
  outline: 0;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1080px) {
  .btn {
    padding: var(--button-padding-y) var(--button-padding-x);
  }
}
.btn-block {
  @apply relative inline-flex items-center;
}
.btn-block__icon {
  @apply mr-2;
}
.btn-block__icon svg {
  @apply h-4;
}
.btn-primary::before {
  content: "";
  @apply bg-opacity-60 absolute top-4 right-3 left-3 h-12;
  filter: blur(16px);
  background-color: var(--primary-color) !important;
}
.btn-sm {
  padding: var(--button-sm-padding-y) var(--button-sm-padding-x);
  font-size: var(--button-sm-font-size);
}
.btn-lg {
  padding: var(--button-lg-padding-y) var(--button-lg-padding-x);
  font-size: var(--button-lg-font-size);
}
.btn-forms {
  padding: var(--button-forms-padding-y) var(--button-forms-padding-x);
  font-size: var(--button-forms-font-size);
}
.btn-primary {
  background-color: var(--primary-color) !important;
  color: var(--primary-color-invert);
}
.btn-primary svg {
  fill: var(--primary-color-invert);
}
.btn-primary:hover {
  @apply text-primary;
  background-color: var(--primary-color-hover) !important;
}
.btn-primary:hover svg {
  @apply fill-primary;
}
.btn-primary:hover::before {
  background-color: var(--primary-color-hover) !important;
}
.btn-outline-primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.btn-outline-primary svg {
  fill: var(--primary-color);
}
.btn-outline-primary:hover {
  color: var(--primary-color-invert);
  background-color: var(--primary-color) !important;
}
.btn-outline-primary:hover svg {
  fill: var(--primary-color-invert);
}
.btn-secondary {
  background-color: var(--secondary-color) !important;
  color: var(--secondary-color-invert);
}
.btn-secondary svg {
  fill: var(--secondary-color-invert);
}
.btn-secondary:hover {
  @apply text-primary;
  background-color: var(--secondary-color-hover) !important;
}
.btn-secondary:hover svg {
  @apply fill-primary;
}
.btn-secondary:hover::before {
  background-color: var(--secondary-color-hover) !important;
}
.btn-outline-secondary {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}
.btn-outline-secondary svg {
  fill: var(--secondary-color);
}
.btn-outline-secondary:hover {
  color: var(--secondary-color-invert);
  background-color: var(--secondary-color) !important;
}
.btn-outline-secondary:hover svg {
  fill: var(--secondary-color-invert);
}
.btn-light {
  background-color: var(--light-color) !important;
  color: var(--light-color-invert);
}
.btn-light svg {
  fill: var(--light-color-invert);
}
.btn-light:hover {
  @apply text-primary;
  background-color: var(--light-color-hover) !important;
}
.btn-light:hover svg {
  @apply fill-primary;
}
.btn-light:hover::before {
  background-color: var(--light-color-hover) !important;
}
.btn-outline-light {
  border-color: var(--light-color);
  color: var(--light-color);
}
.btn-outline-light svg {
  fill: var(--light-color);
}
.btn-outline-light:hover {
  color: var(--light-color-invert);
  background-color: var(--light-color) !important;
}
.btn-outline-light:hover svg {
  fill: var(--light-color-invert);
}

.card {
  @apply overflow-hidden;
  transition: transform 0.5s ease-in-out;
}
.card--news:hover .card-header img {
  @apply scale-110;
}
.card--news:hover .card-content__title {
  @apply prose-headings:text-primary;
}
@media (max-width: 767.98px) {
  .card--news {
    @apply flex items-center;
  }
}
.card--certify:hover .card-header img {
  @apply scale-110;
}
.card--certify:hover .card-content__title {
  @apply prose-headings:text-secondary;
}
.card--solution:hover .card-header {
  @apply prose-headings:text-warning;
}
.card--solution:hover .card-content__image img {
  @apply scale-110;
}
.card--solution:hover .card-content__text {
  @apply opacity-90;
}
.card--management:hover .card-header img {
  @apply scale-110;
}
.card--management:hover .card-content__actions {
  @apply text-warning;
}
.card--management:hover .card-content__actions svg {
  @apply fill-warning;
}
@media (min-width: 1080px) {
  .card--vision {
    @apply py-10;
  }
}
.card--provide {
  @apply p-4 border border-primary;
}
@media (min-width: 1080px) {
  .card--provide {
    @apply py-12 px-14;
  }
}
.card--provide-2 {
  @apply overflow-hidden;
}
@media (min-width: 1080px) {
  .card--provide-2 {
    height: 450px;
  }
}
.card--feature {
  @apply bg-white p-6 lg:py-8 overflow-hidden;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
}
.card--serve {
  @apply bg-white overflow-hidden;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
}
.card--location {
  @apply p-4;
}
@media (min-width: 1080px) {
  .card--location {
    @apply p-6;
  }
}
.card--cold {
  @apply p-4;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1080px) {
  .card--cold {
    @apply py-10 px-8;
  }
}
.card--news .card-header img, .card--certify .card-header img {
  transition: transform 0.5s ease-in-out;
}
@media (max-width: 767.98px) {
  .card--news .card-header {
    @apply aspect-square overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
    width: 125px;
  }
}
.card--solution .card-header {
  @apply mb-4 prose-headings:text-1xl;
}
@media (min-width: 1080px) {
  .card--solution .card-header {
    @apply mb-6;
  }
}
.card--management .card-header {
  @apply aspect-square prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
.card--management .card-header img {
  transition: transform 0.5s ease-in-out;
}
.card--value .card-header {
  @apply mb-5;
}
.card--provide .card-header {
  @apply mb-6;
}
.card--provide-2 .card-header {
  @apply w-full h-full overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
.card--feature .card-header {
  @apply mb-8 prose-headings:text-primary;
}
.card--feature .card-header__icon {
  @apply w-15 prose-img:mx-auto prose-img:w-10;
}
.card--feature .card-header__icon::before {
  content: "";
  @apply absolute -bottom-4 right-0 left-0 bg-primary;
  height: 2px;
}
.card--career .card-header {
  @apply mx-auto aspect-square rounded-full overflow-hidden bg-gray prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
  width: 200px;
}
@media (max-width: 767.98px) {
  .card--news .card-content {
    @apply flex-1 pl-4;
  }
}
@media (min-width: 1080px) {
  .card--news .card-content {
    @apply mt-5;
  }
}
.card--news .card-content__tag .tag {
  @apply bg-warning py-1 px-3 uppercase;
}
@media (min-width: 1080px) {
  .card--news .card-content__tag .tag {
    @apply py-2 px-4;
  }
}
.card--news .card-content__tag .date-icon svg {
  @apply h-4 fill-grayDark;
}
.card--news .card-content__title {
  @apply prose-headings:text-rg prose-headings:line-clamp-2;
}
@media (min-width: 1080px) {
  .card--news .card-content__title {
    @apply prose-headings:text-1xl prose-headings:h-[50px];
  }
}
.card--solution .card-content {
  aspect-ratio: 4/3;
}
.card--solution .card-content__image {
  @apply prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center overflow-hidden;
  aspect-ratio: inherit;
}
.card--solution .card-content__image img {
  transition: transform 0.5s ease-in-out;
}
.card--solution .card-content__text {
  @apply absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center opacity-0;
  transition: transform 0.5s ease-in-out;
}
.card--solution .card-content__text .dialog {
  @apply bg-white flex flex-col items-center justify-between p-6 prose-p:text-black prose-p:line-clamp-4 prose-p:h-24;
  width: calc(100% - 52px);
  height: calc(100% - 48px);
}
.card--management .card-content__title {
  @apply prose-p:font-bold;
}
@media (min-width: 1080px) {
  .card--management .card-content__title {
    @apply prose-headings:text-2xl;
  }
}
.card--provide .card-content__title {
  @apply mb-3 text-primary;
}
.card--provide-2 .card-content {
  @apply absolute flex flex-col justify-end top-0 bottom-0 right-0 left-0 p-5;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(0, 0, 0, 0) 100%);
}
.card--provide-2 .card-content__title {
  @apply mb-2 text-primary;
}
@media (min-width: 1080px) {
  .card--provide-2 .card-content__title {
    @apply prose-headings:text-4xl;
  }
}
@media (min-width: 1080px) {
  .card--serve .card-content {
    @apply -mt-10;
  }
}
.card--location .card-content__actions .item {
  @apply inline-flex items-center text-primary hover:text-warning;
}
@media (min-width: 1080px) {
  .card--location .card-content__actions .item-icon {
    @apply prose-img:h-5;
  }
}

.media--news:hover .media-image img {
  @apply scale-110;
}
.media--news:hover .media-content__title {
  @apply prose-headings:text-primary;
}
.media--news-big {
  @apply items-center;
}
@media (min-width: 1080px) {
  .media--news-big {
    @apply col-span-2;
  }
}
@media (min-width: 1080px) {
  .media--future {
    @apply flex items-center;
  }
}
.media--future:nth-of-type(odd) .media-image {
  @apply rounded-tl-[200px];
}
.media--future:nth-of-type(even) .media-image {
  @apply rounded-br-[200px];
}
@media (min-width: 1080px) {
  .media--future:nth-of-type(even) .media-image {
    @apply order-2;
  }
}
@media (min-width: 1080px) {
  .media--future:nth-of-type(even) .media-content {
    @apply pl-0 pr-10;
  }
}
.media--future-opt {
  @apply mt-10;
}
@media (min-width: 1080px) {
  .media--future-opt {
    @apply mt-16 flex items-center;
  }
}
.media--problem {
  @apply bg-white flex items-center p-4 overflow-hidden;
}
@media (min-width: 1080px) {
  .media--problem {
    @apply py-6 px-10;
  }
}
.media--line {
  @apply w-full;
}
@media (min-width: 1080px) {
  .media--line {
    @apply w-1/2;
  }
}
.media--solved {
  @apply flex;
}
@media (min-width: 1080px) {
  .media--solved {
    @apply py-6 px-7;
  }
}
.media--solved:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
}
.media--line .media-dialog {
  @apply bg-white w-full;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1080px) {
  .media--line .media-dialog {
    width: 400px;
  }
}
.media--news .media-image {
  @apply aspect-square overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
@media (max-width: 1079.98px) {
  .media--news .media-image {
    width: 125px;
  }
}
@media (min-width: 1080px) {
  .media--news .media-image {
    @apply aspect-video;
  }
}
.media--news .media-image img {
  transition: transform 0.5s ease-in-out;
}
@media (min-width: 1080px) {
  .media--news-big .media-image {
    width: calc(50% - 16px);
  }
}
@media (min-width: 1080px) {
  .media--news-small .media-image {
    height: 150px;
  }
}
.media--future .media-image {
  @apply prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
@media (min-width: 1080px) {
  .media--future .media-image {
    @apply w-1/2;
  }
}
.media--problem .media-image {
  @apply w-8 prose-img:w-8 lg:w-12 lg:prose-img:w-12;
}
.media--solved .media-image::before {
  content: "";
  @apply absolute right-0 left-0 -bottom-4 bg-primary;
  height: 2px;
}
@media (min-width: 1080px) {
  .media--solved .media-image {
    @apply w-18 h-fit prose-img:h-12 prose-img:mx-auto;
  }
}
.media--mobility .media-image {
  width: 120px;
}
.media--news .media-content {
  @apply pl-4;
}
.media--news .media-content__tag .tag {
  @apply bg-warning py-1 px-3 uppercase;
}
@media (min-width: 1080px) {
  .media--news .media-content__tag .tag {
    @apply py-2 px-4;
  }
}
.media--news .media-content__tag .date-icon svg {
  @apply h-4 fill-grayDark;
}
.media--news .media-content__title {
  @apply prose-headings:text-rg prose-headings:line-clamp-2;
}
@media (min-width: 1080px) {
  .media--news-big .media-content {
    @apply pl-10;
  }
}
.media--news-big .media-content__title {
  @apply prose-p:hidden;
}
@media (min-width: 1080px) {
  .media--news-big .media-content__title {
    @apply mt-8 mb-12 prose-headings:text-4xl prose-p:block prose-headings:h-20 prose-p:mt-4 prose-p:line-clamp-3 prose-p:h-18;
  }
}
@media (min-width: 1080px) {
  .media--news-small .media-content {
    @apply pl-6;
  }
}
@media (min-width: 1080px) {
  .media--news-small .media-content__title {
    @apply prose-headings:text-1xl prose-headings:h-[50px];
  }
}
.media--future .media-content {
  @apply flex-1 pt-6;
}
@media (min-width: 1080px) {
  .media--future .media-content {
    @apply pl-10 pt-0;
  }
}
@media (min-width: 1080px) {
  .media--future .media-content__title {
    @apply prose-headings:text-5xl;
  }
}
.media--future-opt .media-content {
  @apply flex-1 pb-6;
}
@media (min-width: 1080px) {
  .media--future-opt .media-content {
    @apply pr-10 pb-0;
  }
}
@media (min-width: 1080px) {
  .media--future-opt .media-content__title {
    @apply prose-headings:text-5xl;
  }
}
.media--problem .media-content {
  @apply flex pl-4 text-primary;
}
@media (min-width: 1080px) {
  .media--problem .media-content {
    @apply prose-headings:text-1xl;
  }
}
.media--solved .media-content {
  @apply flex-1;
}
@media (min-width: 1080px) {
  .media--solved .media-content {
    @apply pl-6;
  }
}
.media--mobility .media-content {
  @apply pl-4;
}
@media (min-width: 1080px) {
  .media--mobility .media-content {
    @apply pl-8;
  }
}

.table thead th {
  @apply px-2 pb-4 border-0 font-bold align-bottom;
  text-align: inherit;
  text-align: -webkit-match-parent;
}
@media (min-width: 1080px) {
  .table thead th {
    @apply text-1xl;
  }
  .table thead th:nth-child(3) {
    width: 300px;
  }
  .table thead th:nth-child(4) {
    width: 24px;
  }
}
.table tbody td {
  @apply px-2 py-4 align-top border-b border-dashed border-gray;
}
@media (min-width: 1080px) {
  .table tbody td:nth-child(1) {
    @apply font-bold text-primary;
  }
  .table tbody td:nth-child(3) {
    width: 300px;
  }
  .table tbody td:nth-child(4) {
    @apply align-middle;
    width: 24px;
  }
}

.form-input, .form-textarea, .form-select {
  @apply text-black border-gray w-full px-4 py-3 transition ease-in-out ring-0 placeholder:text-gray focus:border-secondary !important;
}
.form-input[disabled], .form-textarea[disabled], .form-select[disabled] {
  @apply bg-gray;
}
.career-search__form .form-input, .career-search__form .form-textarea, .career-search__form .form-select {
  @apply placeholder:text-black !important;
}
.form-validate .form-input, .form-validate .form-textarea, .form-validate .form-select {
  @apply border-primary !important;
}
.form-validate__feedback {
  @apply mt-1 text-primary;
}
.form-validate__feedback small {
  @apply text-sm;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }
  50% {
    background-position-y: -2px;
  }
  100% {
    background-position-y: 0;
  }
}
.form-check {
  @apply relative inline-flex items-center cursor-pointer;
}
.form-check input[type=checkbox]:checked {
  @apply bg-primary border-secondary;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  animation: checkmark 0.2s ease-in;
}
.form-check input[type=radio] {
  transition: 0.25s;
}
.form-check input[type=radio]:checked {
  @apply bg-primary border-secondary;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  animation: checkmark 0.2s ease-in;
}
.form-check label {
  @apply inline-block flex-1 pl-2 mb-0 cursor-pointer;
}

.navbar {
  @apply relative;
  transition: ease-in-out 0.25s, height 0.25s;
}
@media (max-width: 1079.98px) {
  .navbar {
    @apply top-4;
  }
}
.navbar--fixed {
  @apply fixed top-4;
}
.navbar--fixed .navbar-front {
  @apply hidden;
}
.navbar--fixed .navbar-back {
  @apply block;
}
.navbar-front {
  @apply opacity-100 visible;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}
@media (max-width: 1079.98px) {
  .navbar-front {
    @apply hidden;
  }
}
.navbar-back {
  transition: opacity 0.5s ease, visibility 0.5s ease;
}
@media (min-width: 768px) and (max-width: 1079.98px) {
  .navbar-back {
    max-width: 100% !important;
  }
}
@media (min-width: 1080px) {
  .navbar-back {
    @apply hidden;
  }
}
.navbar-back .navbar-dialog {
  @apply bg-white px-4;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
.navbar-top {
  height: var(--navbar-top-height);
}
.navbar-main {
  height: var(--navbar-main-height);
}
.navbar-main::before {
  content: "";
  @apply bg-primary bg-opacity-60 absolute top-4 right-8 left-8 h-full;
  filter: blur(16px);
}
.navbar-logo {
  @apply prose-img:h-16;
}
@media (min-width: 1080px) {
  .navbar-logo {
    @apply prose-img:h-22;
    margin-right: auto;
  }
}
.navbar-menu {
  @apply hidden lg:flex;
}
.navbar-menu__list {
  @apply h-full;
}
.navbar-menu__list .active > a > svg {
  @apply fill-secondary;
}
.navbar-menu__list-item {
  @apply flex items-center h-full border-b-2 border-b-transparent;
}
@media (min-width: 1080px) {
  .navbar-menu__list-item {
    height: var(--navbar-main-height);
  }
  .navbar-back .navbar-menu__list-item {
    height: 88px;
  }
}
@media (max-width: 1079.98px) {
  .navbar-back .navbar-social {
    @apply hidden;
  }
}
.navbar-social__item > p {
  @apply text-xs;
  margin-left: 28px;
}
.navbar-social__item-link {
  @apply inline-flex items-center text-primary lg:text-1xl font-bold transition ease-in-out;
  user-select: none;
}
.navbar-social__item-link:hover {
  @apply text-warning;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.dropdown-toggle {
  @apply inline-flex items-center;
}
.dropdown--navbar .dropdown-toggle {
  @apply after:hidden;
}
.navbar-front .dropdown--navbar .dropdown-toggle svg {
  @apply fill-white;
}
.dropdown--navbar .dropdown-toggle.show {
  @apply text-warning border-b-warning;
}
.dropdown--navbar .dropdown-toggle.show svg {
  @apply -rotate-180 fill-warning;
}
.dropdown-toggle .arrow {
  @apply ml-2;
}
.dropdown-menu {
  @apply bg-white p-6 shadow rounded-none;
}
@media (min-width: 1080px) {
  .dropdown-menu {
    min-width: 300px;
  }
}
.dropdown--navbar .dropdown-menu {
  @apply mt-0;
}
.dropdown--navbar .dropdown-menu__list a {
  @apply hover:text-warning;
}
.dropdown--lang .dropdown-menu__list img {
  @apply h-4;
}

.accordion-item {
  @apply bg-white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
}
.accordion-toggle[aria-expanded=true] {
  background-color: inherit;
  transition-duration: 0.75s;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-toggle[aria-expanded=true] .icon svg {
  @apply rotate-180;
}
.accordion-toggle[aria-expanded=false] {
  transition-duration: 0.75s;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-toggle .title {
  @apply flex-1 pr-4;
}
.accordion-toggle .icon svg {
  @apply h-4 fill-primary;
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-content {
  transition-duration: 0.5s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-content[aria-hidden=true] {
  height: 0;
  opacity: 0;
  transform: translate3d(0, -0.5rem, 0);
  visibility: hidden;
}
.accordion-content[aria-hidden=false] {
  height: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

@media (min-width: 1080px) {
  .nav-solved__header {
    @apply grid gap-4 overflow-y-scroll;
    height: 550px;
  }
  .nav-solved__header-item {
    @apply py-4 px-6;
  }
}
.nav-solved__header-item {
  @apply flex items-center border border-[#AEAEAE] transition ease-in-out;
}
.nav-solved__header-item.active {
  @apply border-2 border-primary;
}
.nav-solved__header-item.active:hover {
  @apply border-primary prose-headings:text-primary;
}
.nav-solved__header-item:hover {
  @apply border-2 border-secondary prose-headings:text-secondary;
}
@media (min-width: 1080px) {
  .nav-solved__header-item .image {
    @apply prose-img:w-22;
  }
}
.nav-solved__header-item .title {
  @apply flex-1;
}
@media (min-width: 1080px) {
  .nav-solved__header-item .title {
    @apply pl-4;
  }
}
@media (min-width: 1080px) {
  .nav-solved__content-item {
    @apply p-10;
  }
}
@media (min-width: 1080px) {
  .nav-solved__content-item .item-row {
    @apply flex flex-wrap -m-6;
  }
}
.nav-solved__content-item .item-row__title, .nav-solved__content-item .item-row__thumb {
  @apply w-full p-6;
}
@media (min-width: 1080px) {
  .nav-solved__content-item .item-row__title {
    @apply w-2/5;
  }
}
.nav-solved__content-item .item-row__thumb {
  @apply mix-blend-multiply;
}
@media (min-width: 1080px) {
  .nav-solved__content-item .item-row__thumb {
    @apply w-3/5;
  }
}

@media (max-width: 1079.98px) {
  .tab--price .tab-header {
    @apply flex-nowrap whitespace-nowrap gap-2 overflow-x-scroll;
  }
}
@media (min-width: 1080px) {
  .tab--price .tab-header {
    @apply -m-3;
  }
  .tab--price .tab-header > div {
    @apply p-3;
  }
}
.tab-link {
  @apply bg-white text-primary;
}
.tab-link[aria-selected=true] {
  @apply bg-primary border-primary text-warning;
}
.tab-content__group {
  @apply mt-6;
}
@media (min-width: 1080px) {
  .tab-content__group {
    @apply mt-10;
  }
}
.tab-content__group .item-image img {
  @apply mx-auto;
  height: 125px;
}
.tab-content__group .item-content__price span {
  @apply font-bold text-primary;
}
@media (min-width: 1080px) {
  .tab-content__group .item-content__price span {
    @apply text-3xl;
  }
}

.modal {
  --bs-modal-border-radius: 0;
}
.modal-backdrop {
  --bs-backdrop-opacity: .75;
}
.modal-close {
  @apply absolute -top-10 right-0;
}
@media (min-width: 1080px) {
  .modal--concern .modal-dialog, .modal--management .modal-dialog, .modal--certify .modal-dialog {
    max-width: 900px;
  }
}
.modal--concern .modal-body__content, .modal--management .modal-body__content {
  height: calc(100vh - 250px);
  overflow-y: scroll;
}
.modal--concern .modal-body {
  @apply p-4 lg:p-6;
}
.modal--management .modal-body {
  @apply p-4 lg:p-14;
}
.modal--management .modal-body__content .content {
  @apply pt-6;
}
@media (min-width: 1080px) {
  .modal--management .modal-body__content {
    @apply flex;
  }
  .modal--management .modal-body__content .thumbnail {
    @apply sticky top-0 prose-img:w-full;
    width: 300px;
  }
  .modal--management .modal-body__content .content {
    @apply flex-1 pl-10 pt-0;
  }
  .modal--management .modal-body__content .content-title {
    @apply prose-headings:mb-2 prose-headings:text-5xl;
  }
}
@media (min-width: 1080px) {
  .modal--certify .modal-body {
    @apply py-10 px-14;
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.25s;
}

.jumbotron {
  height: 175px;
}
@media (min-width: 1080px) {
  .jumbotron {
    height: 300px;
  }
}
.jumbotron-image {
  @apply w-1/2 h-full ml-auto;
}
.jumbotron--about .jumbotron-image {
  @apply bg-primary prose-img:opacity-60;
}
.jumbotron-body {
  @apply absolute top-0 right-0 left-0 bg-no-repeat bg-left bg-cover;
  background: linear-gradient(90deg, rgb(37, 43, 91) 50%, rgba(0, 0, 0, 0) 100%);
}
.jumbotron--page .jumbotron-body {
  @apply bg-primary;
}
@media (min-width: 1080px) {
  .jumbotron-title {
    @apply prose-headings:text-4xl;
    width: 400px;
  }
}
.jumbotron--page .jumbotron-title {
  @apply w-full;
}

.breadcrumb {
  @apply mb-5;
}
.breadcrumb-item + .breadcrumb-item {
  @apply pl-1;
}
.breadcrumb-item + .breadcrumb-item::before {
  @apply inline-block pr-1 float-left text-white;
  content: "/";
}
.breadcrumb-item.active {
  @apply text-secondary;
}

@media (min-width: 1080px) {
  .searchbox {
    @apply ml-8;
  }
}
.searchbox-push .close {
  @apply hidden;
}
.searchbox-open .searchbox-push .close {
  @apply block;
}
.searchbox-open .searchbox-push .search {
  @apply hidden;
}
.searchbox-dialog {
  @apply top-1/2 left-0 right-12 flex opacity-0 invisible;
  transform: translate3d(0, -100%, 0);
  transition-duration: 0.25s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.searchbox-open .searchbox-dialog {
  @apply opacity-100 visible;
  transform: translate3d(0, 0, 0) translateY(-50%);
}
.searchbox-dialog__form {
  flex: 1 0 auto;
}
.searchbox-dialog__input {
  padding-right: 50px !important;
  height: 42px;
}
.searchbox-dialog__submit {
  width: 42px;
  height: 42px;
}

.hamburger {
  @apply relative block lg:hidden w-10 h-10;
}
.hamburger-push {
  @apply flex items-center w-full h-full transition ease-in-out;
}
.hamburger-push > span {
  @apply bg-primary absolute top-5 block w-10 h-1 transition ease-in-out;
}
.hamburger-push > span::before, .hamburger-push > span::after {
  content: "";
  @apply bg-primary absolute w-10 h-1 transition ease-in-out;
}
.hamburger-push > span::before {
  @apply -top-[10px];
}
.hamburger-push > span::after {
  @apply top-[10px];
}
.hamburger-push.show > span {
  @apply bg-transparent;
}
.hamburger-push.show > span::before {
  transform: rotateZ(45deg) scaleX(1) translate(8px, 8px);
}
.hamburger-push.show > span::after {
  transform: rotateZ(-45deg) scaleX(1) translate(7px, -7px);
}
.hamburger-menu {
  top: calc(var(--navbar-main-height) + 16px);
  height: calc(100vh - (var(--navbar-main-height) + 48px));
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.1);
  transform: translate3d(0, -100%, 0);
}
.hamburger-menu.show {
  @apply visible opacity-100;
  transform: translate3d(0, 0, 0);
}

.treeview > a {
  @apply flex items-center;
}
.treeview > a span {
  @apply ml-auto;
}
.treeview__menu {
  @apply hidden;
}
.treeview__menu-list {
  @apply ml-4 mt-4;
}
.treeview__open > a > span {
  transform: rotate(-90deg);
}

.pagination-item__button {
  @apply font-bold;
  line-height: 24px;
}
.pagination-item__button.disabled {
  @apply cursor-not-allowed opacity-50;
}
.pagination-item__button.current {
  @apply text-primary text-2xl;
}

.tooltip {
  --tooltip-background-color: #354856;
  --tooltip-text-color: #fff;
  --tooltip-position-top: auto;
  --tooltip-position-bottom: calc(100% + 8px);
  --tooltip-position-border-top: auto;
  --tooltip-position-border-bottom: 100%;
  --tooltip-position-left: 50%;
  --tooltip-position-right: auto;
  --tooltip-position-border-left: 50%;
  --tooltip-position-border-right: auto;
  @apply relative inline-flex;
}
.tooltip::before, .tooltip::after {
  @apply absolute pointer-events-none opacity-0 transition-opacity;
}
.tooltip::before {
  content: attr(data-tooltip);
  color: var(--tooltip-text-color);
  background-color: var(--tooltip-background-color);
  @apply absolute w-max py-1 px-2 text-sm;
}
.tooltip::after {
  @apply block w-0 h-0 border-solid border-4;
  content: "";
  border-color: var(--tooltip-background-color) transparent transparent transparent;
}
.tooltip:hover::before, .tooltip:hover::after {
  @apply opacity-100;
  transition-delay: 75ms;
}
.tooltip--position::before, .tooltip--position::after {
  transform: translate(-50%);
}
.tooltip--position::before {
  top: var(--tooltip-position-top);
  bottom: var(--tooltip-position-bottom);
  left: var(--tooltip-position-left);
  right: var(--tooltip-position-right);
}
.tooltip--position::after {
  top: var(--tooltip-position-border-top);
  bottom: var(--tooltip-position-border-bottom);
  left: var(--tooltip-position-border-left);
  right: var(--tooltip-position-border-right);
  border-color: var(--tooltip-background-color) transparent transparent transparent;
}

.loading {
  @apply inline-block aspect-square pointer-events-none;
  background-color: currentColor;
  width: var(--loading-size);
  mask-size: 100%;
  mask-repeat: no-repeat;
  mask-position: center;
}
.loading-sm {
  width: var(--loading-sm-size);
}
.loading-lg {
  width: var(--loading-lg-size);
}
.loading-xl {
  width: var(--loading-xl-size);
}
.loading-primary {
  color: var(--primary-color);
}
.loading-secondary {
  color: var(--secondary-color);
}
.loading-spinner {
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHN0cm9rZT0nIzAwMCcgdmlld0JveD0nMCAwIDI0IDI0JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxzdHlsZT4uc3Bpbm5lcl9WOG0xe3RyYW5zZm9ybS1vcmlnaW46Y2VudGVyO2FuaW1hdGlvbjpzcGlubmVyX3pLb2EgMnMgbGluZWFyIGluZmluaXRlfS5zcGlubmVyX1Y4bTEgY2lyY2xle3N0cm9rZS1saW5lY2FwOnJvdW5kO2FuaW1hdGlvbjpzcGlubmVyX1lwWlMgMS41cyBlYXNlLW91dCBpbmZpbml0ZX1Aa2V5ZnJhbWVzIHNwaW5uZXJfektvYXsxMDAle3RyYW5zZm9ybTpyb3RhdGUoMzYwZGVnKX19QGtleWZyYW1lcyBzcGlubmVyX1lwWlN7MCV7c3Ryb2tlLWRhc2hhcnJheTowIDE1MDtzdHJva2UtZGFzaG9mZnNldDowfTQ3LjUle3N0cm9rZS1kYXNoYXJyYXk6NDIgMTUwO3N0cm9rZS1kYXNob2Zmc2V0Oi0xNn05NSUsMTAwJXtzdHJva2UtZGFzaGFycmF5OjQyIDE1MDtzdHJva2UtZGFzaG9mZnNldDotNTl9fTwvc3R5bGU+PGcgY2xhc3M9J3NwaW5uZXJfVjhtMSc+PGNpcmNsZSBjeD0nMTInIGN5PScxMicgcj0nOS41JyBmaWxsPSdub25lJyBzdHJva2Utd2lkdGg9JzMnPjwvY2lyY2xlPjwvZz48L3N2Zz4=);
}
.loading-ring {
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nNDQnIGhlaWdodD0nNDQnIHZpZXdCb3g9JzAgMCA0NCA0NCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyBzdHJva2U9JyNmZmYnPjxnIGZpbGw9J25vbmUnIGZpbGwtcnVsZT0nZXZlbm9kZCcgc3Ryb2tlLXdpZHRoPScyJz48Y2lyY2xlIGN4PScyMicgY3k9JzIyJyByPScxJz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdyJyBiZWdpbj0nMHMnIGR1cj0nMS44cycgdmFsdWVzPScxOyAyMCcgY2FsY01vZGU9J3NwbGluZScga2V5VGltZXM9JzA7IDEnIGtleVNwbGluZXM9JzAuMTY1LCAwLjg0LCAwLjQ0LCAxJyByZXBlYXRDb3VudD0naW5kZWZpbml0ZScgLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdzdHJva2Utb3BhY2l0eScgYmVnaW49JzBzJyBkdXI9JzEuOHMnIHZhbHVlcz0nMTsgMCcgY2FsY01vZGU9J3NwbGluZScga2V5VGltZXM9JzA7IDEnIGtleVNwbGluZXM9JzAuMywgMC42MSwgMC4zNTUsIDEnIHJlcGVhdENvdW50PSdpbmRlZmluaXRlJyAvPjwvY2lyY2xlPjxjaXJjbGUgY3g9JzIyJyBjeT0nMjInIHI9JzEnPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9J3InIGJlZ2luPSctMC45cycgZHVyPScxLjhzJyB2YWx1ZXM9JzE7IDIwJyBjYWxjTW9kZT0nc3BsaW5lJyBrZXlUaW1lcz0nMDsgMScga2V5U3BsaW5lcz0nMC4xNjUsIDAuODQsIDAuNDQsIDEnIHJlcGVhdENvdW50PSdpbmRlZmluaXRlJyAvPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9J3N0cm9rZS1vcGFjaXR5JyBiZWdpbj0nLTAuOXMnIGR1cj0nMS44cycgdmFsdWVzPScxOyAwJyBjYWxjTW9kZT0nc3BsaW5lJyBrZXlUaW1lcz0nMDsgMScga2V5U3BsaW5lcz0nMC4zLCAwLjYxLCAwLjM1NSwgMScgcmVwZWF0Q291bnQ9J2luZGVmaW5pdGUnIC8+PC9jaXJjbGU+PC9nPjwvc3ZnPg==);
}

.maps-title {
  @apply bg-white mx-auto m-4 p-4 overflow-hidden prose-headings:text-primary;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.maps-title__form {
  @apply mt-4;
}
.maps-title__input {
  padding-right: 50px !important;
}
.maps-title__submit {
  @apply top-1/2 -translate-y-1/2 flex items-center justify-center w-8 h-8 rounded-full overflow-hidden;
}
@media (min-width: 1080px) {
  .maps-title {
    @apply flex items-center w-[900px] px-12 py-8 prose-headings:text-6xl;
  }
  .maps-title__form {
    @apply mt-0 pl-8;
  }
}
.maps-content .map {
  @apply bg-transparent;
  /* Reset Zoom button first */
  /* Then Zoom In button */
  /* Then Zoom Out button */
}
.maps-content .map .leaflet-popup-content {
  @apply text-sm prose-headings:text-primary prose-p:m-0;
  max-width: 190px;
}
.maps-content .map .leaflet-popup-content-wrapper {
  @apply shadow-lg rounded-none;
}
.maps-content .map .leaflet-popup-content .item-header {
  @apply mb-4 prose-headings:uppercase prose-headings:mb-1 prose-a:text-black hover:prose-a:text-primary;
}
.maps-content .map .leaflet-popup-content .item-header__image {
  @apply mb-2 aspect-video overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
.maps-content .map .leaflet-popup-content .item-content {
  @apply grid gap-2 prose-headings:text-sm;
}
.maps-content .map .leaflet-popup-tip {
  @apply shadow-lg;
}
.maps-content .map .leaflet-pane {
  @apply z-10;
}
.maps-content .map .mapTooltip {
  @apply bg-white absolute p-2 rounded-sm shadow-md overflow-hidden z-30;
}
.maps-content .map .zoomButton {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #000;
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  font-weight: bold;
  left: 10px;
  user-select: none;
}
.maps-content .map .zoomReset {
  bottom: 96px;
}
.maps-content .map .zoomIn {
  bottom: 52px;
}
.maps-content .map .zoomOut {
  bottom: 12px;
}
.maps-content .map-tooltip {
  @apply flex items-center;
}
.maps-content .map-tooltip p {
  @apply pl-2;
}
.maps-content .map-tooltip img {
  @apply h-3;
}
.maps-content .map .marker {
  @apply inline-flex items-center justify-center text-white text-xs rounded-full;
}
.maps-content .map .marker-green {
  @apply w-5 h-5 bg-[#017D37];
}
.maps-content .map .marker-pink {
  @apply w-7 h-7 bg-[#AE0A88];
}
.maps-content .map .marker-teal {
  @apply w-9 h-9 bg-[#009187];
}
.maps-content .map .marker-red {
  @apply w-11 h-11 bg-[#F9104F];
}

.post-title__tag .tag {
  @apply bg-warning py-1 px-3 uppercase;
}
@media (min-width: 1080px) {
  .post-title__tag .tag {
    @apply py-2 px-4;
  }
}
.post-title__tag .date-icon svg {
  @apply h-4 fill-grayDark;
}
.post-title__loc > div + div::before {
  content: "|";
  @apply relative inline-block px-2 text-gray;
}
.post-detail {
  @apply prose-headings:text-primary prose-headings:mb-2 prose-p:mb-5 prose-a:text-secondary prose-a:font-bold prose-ul:mb-5 prose-ol:mb-5 prose-ul:pl-4 prose-ol:pl-4 prose-ul:list-disc prose-ol:list-decimal;
}
.post-detail p:last-child {
  margin-bottom: 0 !important;
}
.post-detail--tms ul {
  @apply pl-0;
}
.post-detail--tms ul li {
  @apply inline-flex items-center font-bold text-primary;
}
.post-detail--tms ul li::before {
  content: "";
  @apply relative inline-block bg-contain w-8 h-8 mr-4 bg-no-repeat;
  background-image: url(../images/check2-circle.png);
}
.post-detail--tms ul li + li {
  @apply mt-4;
}

.section {
  @apply py-10;
}
@media (min-width: 1080px) {
  .section {
    @apply py-16;
  }
}
.section-title {
  @apply mb-8 prose-headings:text-2xl prose-headings:text-primary;
}
@media (min-width: 1080px) {
  .section-title {
    @apply mb-14 prose-headings:text-8xl mx-auto;
  }
}
.home-solution .section-title {
  @apply pt-12 text-center;
}
.home-solution .section-title .actions {
  @apply mt-4 mx-auto;
}
@media (min-width: 1080px) {
  .home-solution .section-title .actions {
    max-width: 850px;
  }
}
.home-event .section-title, .cold-news .section-title {
  @apply flex items-center;
}
.home-event .section-title .actions, .cold-news .section-title .actions {
  @apply ml-auto;
}
@media (min-width: 1080px) {
  .home-client .section-title {
    max-width: 850px;
  }
}
.future-about .section-title .actions, .reward-intro .section-title .actions, .about-milestone .section-title .actions, .services-testi .section-title .actions, .investor-group .section-title .actions, .contact-form .section-title .actions, .career-result .section-title .actions {
  @apply mt-4 mx-auto;
}
@media (min-width: 1080px) {
  .future-about .section-title .actions, .reward-intro .section-title .actions, .about-milestone .section-title .actions, .services-testi .section-title .actions, .investor-group .section-title .actions, .contact-form .section-title .actions, .career-result .section-title .actions {
    max-width: 800px;
  }
}
@media (min-width: 1080px) {
  .future-about .section-title .actions, .reward-intro .section-title .actions {
    max-width: 800px;
  }
}
@media (min-width: 1080px) {
  .about-group .section-title {
    @apply prose-headings:text-2xl;
  }
}
.about-group .section-title .actions {
  @apply mt-6;
}
@media (min-width: 1080px) {
  .action-line .section-title {
    @apply w-3/5 prose-headings:text-5xl;
  }
  .action-line .section-title::before {
    content: "";
    @apply absolute -bottom-4 right-0 left-0 mx-auto bg-primary;
    width: 125px;
    height: 2px;
  }
}
.services-problem__solution .section-title {
  @apply mb-0;
  padding-bottom: 64px;
}
@media (min-width: 1080px) {
  .services-problem__solution .section-title {
    padding-bottom: 128px;
  }
  .services-problem__solution .section-title::before {
    content: "";
    @apply bg-primary absolute bottom-8 left-1/2 h-16 -translate-x-1/2;
    width: 1px;
  }
}
.services-choose .section-title__group {
  @apply flex items-end;
}
@media (min-width: 1080px) {
  .services-choose .section-title__group-text {
    @apply mb-0 pr-10;
  }
}
.services-price .section-title .actions {
  @apply mt-4 prose-p:font-bold;
}
@media (min-width: 1080px) {
  .services-price .section-title .actions {
    @apply prose-p:text-2xl;
  }
}
@media (min-width: 1080px) {
  .career-result .section-title .actions {
    @apply prose-p:text-1xl;
  }
}
@media (min-width: 1080px) {
  .services-coverage .section-title {
    @apply flex prose-h2:w-[275px];
  }
  .services-coverage .section-title .actions {
    @apply flex-1 pl-10;
  }
}
.section-last {
  padding-bottom: 120px;
}
@media (min-width: 1080px) {
  .section-last {
    padding-bottom: 132px;
  }
}

.footer::before {
  content: "";
  @apply bg-primary absolute w-full;
  height: 200px;
  mask-image: url(../images/wave.png);
  mask-position: center top;
  margin-top: -100px;
  mask-repeat: no-repeat;
  mask-size: cover;
}
.footer-main {
  @apply pt-12 pb-10;
}
@media (min-width: 1080px) {
  .footer-main {
    @apply pb-16;
  }
}
.footer-list__column {
  @apply prose-h5:mb-3;
}
@media (min-width: 1080px) {
  .footer-list__column {
    @apply prose-h5:mb-6;
  }
}
.footer-social {
  @apply mb-10;
}
@media (min-width: 1080px) {
  .footer-social {
    @apply flex items-center;
  }
  .footer-social__list {
    @apply gap-12 ml-auto;
  }
  .footer-social__list-item {
    @apply gap-12;
  }
}
@media (max-width: 575.98px) {
  .footer-social__brand {
    @apply mb-4;
  }
}
.footer-social__list {
  @apply flex items-center gap-4;
}
.footer-social__list-item {
  @apply gap-4;
}
.footer-address__item {
  @apply prose-p:flex-1;
}
.footer-address__item .icon {
  @apply w-8 prose-img:w-4;
  margin-top: 6px;
}
@media (max-width: 1079.98px) {
  .footer-copy {
    @apply text-center;
  }
}

.main-content {
  @apply mt-8;
}
@media (min-width: 1080px) {
  .main-content {
    @apply mt-9;
  }
}

.stray {
  height: calc(100vh - 80px);
}
@media (min-width: 1080px) {
  .stray {
    @apply text-left;
  }
}
@media (min-width: 1080px) {
  .stray-dialog {
    @apply grid grid-cols-2 gap-16;
  }
}
.stray-image img {
  @apply mx-auto;
  height: 250px;
}
@media (min-width: 1080px) {
  .stray-image img {
    height: 500px;
  }
}
@media (min-width: 1080px) {
  .stray-body p {
    @apply mb-16 text-10xl;
  }
}

@media (min-width: 768px) and (max-width: 1079.98px) {
  .home-banner {
    padding-bottom: 130px;
  }
}
@media (max-width: 767.98px) {
  .home-banner {
    @apply pb-24;
  }
}
.home-solution::before {
  content: "";
  @apply bg-primary absolute w-full;
  height: 200px;
  mask-image: url(../images/wave.png);
  mask-position: center top;
  margin-top: -150px;
  mask-repeat: no-repeat;
  mask-size: cover;
}
@media (min-width: 768px) and (max-width: 1079.98px) {
  .home-solution::before {
    margin-top: -170px;
  }
}
.home-action__body-coverage {
  @apply p-6;
}
@media (min-width: 1080px) {
  .home-action__body-coverage {
    @apply py-8 px-16;
  }
}
.home-action__body-coverage .content {
  @apply mb-6 prose-headings:mb-4;
}
.home-action__body-call {
  @apply bg-contain;
  background-position: right top;
}
@media (min-width: 1080px) {
  .home-action__body-call {
    background-position: left center;
  }
}
.home-action__body-call .content {
  @apply pt-22 pb-14;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(37, 43, 91) 25%);
}
@media (min-width: 1080px) {
  .home-action__body-call .content {
    @apply py-14;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(37, 43, 91) 150%);
  }
}
.home-action__body-call .content-block {
  @apply ml-auto px-10 prose-headings:mb-8;
}
@media (min-width: 1080px) {
  .home-action__body-call .content-block {
    @apply w-3/5 pl-12 pr-22;
  }
}
@media (min-width: 1080px) {
  .home-about__image {
    @apply w-2/3 !important;
  }
}
.home-about__image-play {
  @apply bg-primary inline-flex items-center justify-center top-1/2 left-1/2 w-18 h-18 rounded-full overflow-hidden;
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease-in-out;
}
.home-about__image-play svg {
  @apply h-6;
}
.home-about__image-play:hover {
  @apply bg-warning;
}
.home-about__image-play:hover svg {
  @apply fill-primary;
}
@media (min-width: 1080px) {
  .home-about__content {
    @apply w-1/3 !important;
  }
}
.home-about__content-title {
  @apply mb-4;
}
@media (min-width: 1080px) {
  .home-about__content-title {
    @apply mb-6;
  }
}
.home-client__body-list {
  @apply mb-6;
}
.home-client__body-list:last-child {
  @apply mb-0 pb-0 border-b-0;
}
@media (min-width: 1080px) {
  .home-client__body-list {
    @apply mb-12 pb-12;
  }
}
.home-client__body-list .title {
  @apply flex items-center justify-center mb-6 border-b border-b-grayMedium prose-headings:bg-grayMedium prose-headings:py-2 prose-headings:px-4 prose-headings:text-1xl prose-headings:text-primary;
}
@media (min-width: 1080px) {
  .home-client__body-list .title {
    @apply mb-8 prose-headings:text-5xl;
  }
}

.about-intro__started {
  @apply py-10;
}
@media (min-width: 1080px) {
  .about-intro__started {
    @apply py-16;
  }
}
@media (min-width: 1080px) {
  .about-intro__started-image {
    @apply absolute left-0 prose-img:mt-12;
    width: calc(50% - 32px);
  }
  .about-intro__started-image:before {
    content: "";
    @apply absolute bg-primary top-0 left-0;
    width: calc(100% - 48px);
    height: calc(100% + 48px);
  }
}
@media (min-width: 1080px) {
  .about-intro__started-text {
    @apply ml-auto w-1/2 mt-12;
  }
}
.about-intro__vision {
  @apply relative mt-10;
}
@media (min-width: 1080px) {
  .about-intro__vision {
    @apply mt-16;
  }
  .about-intro__vision::before {
    content: "";
    @apply absolute bg-primary top-0 left-1/2 h-full -translate-x-1/2;
    width: 1px;
  }
}
.about-group__list-title::before {
  content: "";
  @apply absolute top-1/2 left-0 right-0 bg-primary;
  height: 1px;
}
.about-group__list-title > h6 {
  @apply relative inline-block px-8 bg-grayLight;
}
.about-group__list-content .item {
  @apply flex items-center justify-center bg-white cursor-pointer border border-transparent overflow-hidden transition ease-in-out prose-img:h-10;
  height: 135px;
  border-radius: 50%;
}
.about-group__list-content .item img {
  transition: transform 0.5s ease-in-out;
}
.about-group__list-content .item:hover {
  @apply border-warning;
}
.about-group__list-content .item:hover img {
  @apply scale-110;
}
.about-jumbotron {
  height: 300px;
}
@media (min-width: 1080px) {
  .about-jumbotron {
    height: 460px;
  }
}
.about-jumbotron::before, .about-jumbotron::after {
  content: "";
  @apply absolute right-0 left-0 h-full;
}
.about-jumbotron::before {
  @apply top-0;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 65%);
}
.about-jumbotron::after {
  @apply bottom-0;
  background: linear-gradient(0, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 65%);
}
.about-jumbotron__quote {
  @apply text-primary font-bold italic leading-tight prose-p:text-4xl;
}
@media (min-width: 1080px) {
  .about-jumbotron__quote {
    @apply w-[740px] prose-p:text-8xl;
  }
}
.about-jumbotron__quote p {
  @apply relative;
}
.about-jumbotron__quote p::before, .about-jumbotron__quote p::after {
  content: "";
  @apply relative inline-block bg-no-repeat bg-contain w-8 h-8;
}
.about-jumbotron__quote p::before {
  @apply -top-4;
  background-image: url(../images/icon-quote-left-solid.png);
}
.about-jumbotron__quote p::after {
  @apply -bottom-4;
  background-image: url(../images/icon-quote-right-solid.png);
}
.about-milestone__parent {
  padding-top: 72px;
  padding-bottom: 72px;
}
@media (min-width: 1080px) {
  .about-milestone__parent {
    padding-top: 112px;
    padding-bottom: 112px;
  }
}
.about-milestone__parent::before, .about-milestone__parent::after {
  content: "";
  @apply bg-white absolute left-0 right-0 h-2;
}
.about-milestone__parent::before {
  @apply top-8;
}
.about-milestone__parent::after {
  @apply bottom-8;
}
@media (min-width: 1080px) {
  .about-milestone__thumb::before {
    content: "";
    @apply absolute bottom-0 right-0 w-1/2 h-1 bg-primary;
  }
}
@media (max-width: 1079.98px) {
  .about-milestone__thumb-flex .slick-content {
    @apply border-b-4 border-b-primary;
  }
}
@media (min-width: 1080px) {
  .about-milestone__thumb-flex {
    @apply flex items-center border-b-4 border-b-primary;
  }
  .about-milestone__thumb-flex .slick-content {
    @apply flex-1 w-full mr-6;
  }
}

.action-line__body {
  @apply py-8;
}
@media (min-width: 1080px) {
  .action-line__body {
    @apply py-14;
  }
  .action-line__body::before {
    content: "";
    @apply absolute top-4 left-1/2 h-full bg-primary -translate-x-1/2;
    width: 2px;
  }
  .action-line__body::after {
    content: "";
    @apply absolute -bottom-4 right-0 left-0 mx-auto bg-primary;
    width: 125px;
    height: 2px;
  }
}
.action-line__row {
  @apply grid gap-4;
}
@media (min-width: 1080px) {
  .action-line__row {
    @apply gap-0;
  }
}
@media (min-width: 1080px) {
  .action-line__row-column .media::before {
    content: "";
    @apply absolute top-1/2 left-0 w-full -translate-y-1/2 bg-warning;
    height: 1px;
  }
  .action-line__row-column .media-dialog::after {
    content: "";
    @apply absolute -right-[10px] top-1/2 w-5 h-5 -translate-y-1/2 rounded-full bg-warning;
  }
}
.action-line__row-column:nth-of-type(even) .media {
  @apply ml-auto text-right;
}
.action-line__row-column:nth-of-type(even) .media-dialog {
  @apply text-left;
}
@media (min-width: 1080px) {
  .action-line__row-column:nth-of-type(even) .media-dialog::after {
    @apply -left-[10px] right-[unset];
  }
}

.esg-line {
  @apply py-16;
}
@media (min-width: 1080px) {
  .esg-line {
    @apply py-24;
  }
}
@media (max-width: 1079.98px) {
  .esg-line__title {
    @apply mb-16;
  }
}
@media (min-width: 1080px) {
  .esg-line__title {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-2 border-dashed border-primary rounded-full prose-headings:text-5xl;
    width: 600px;
    height: 600px;
  }
}
.esg-line__row {
  @apply -my-16;
}
.esg-line__row-column {
  @apply py-16;
}
@media (min-width: 1080px) {
  .esg-line__row {
    @apply -my-[120px];
  }
  .esg-line__row-column {
    @apply py-[120px];
  }
}
.esg-line__row-column:nth-child(1) {
  @apply w-full;
}
.esg-line__row-column:nth-child(2), .esg-line__row-column:nth-child(3) {
  @apply w-full;
}
@media (min-width: 1080px) {
  .esg-line__row-column:nth-child(2), .esg-line__row-column:nth-child(3) {
    @apply w-1/2;
  }
}
.esg-line__row-column .item {
  @apply mx-auto rounded-full;
  width: 300px;
  height: 300px;
}
.esg-line__row-column .item:hover .item-image img {
  @apply scale-110;
}
.esg-line__row-column .item:hover .item-content__header {
  width: 300px;
  height: 300px;
}
@media (min-width: 1080px) {
  .esg-line__row-column .item:hover .item-content__header {
    width: 400px;
    height: 400px;
  }
}
.esg-line__row-column .item:hover .item-content__list {
  @apply opacity-100;
}
@media (min-width: 1080px) {
  .esg-line__row-column .item {
    width: 400px;
    height: 400px;
  }
}
.esg-line__row-column .item-image {
  @apply rounded-full overflow-hidden;
}
.esg-line__row-column .item-image img {
  transition: transform 0.5s ease-in-out;
}
.esg-line__row-column .item-content__header {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex-col bg-white bg-opacity-50 rounded-full overflow-hidden prose-img:w-8 prose-img:mb-1;
  width: 150px;
  height: 150px;
  backdrop-filter: blur(5px);
  transition: width 0.5s, height 0.5s;
}
@media (min-width: 1080px) {
  .esg-line__row-column .item-content__header {
    width: 175px;
    height: 175px;
  }
}
.esg-line__row-column .item-content__list {
  @apply absolute top-0 w-full h-full z-10;
  transition: opacity 0.5s;
}
@media (min-width: 1080px) {
  .esg-line__row-column .item-content__list {
    @apply opacity-0;
  }
}
.esg-line__row-column .item-content__list-row:nth-child(1) {
  margin-top: -40px;
}
.esg-line__row-column .item-content__list-row:nth-child(2) {
  margin-top: 40px;
  margin-left: -125px;
  width: calc(100% + 250px);
}
.esg-line__row-column .item-content__list-row:nth-child(3) {
  margin-top: 40px;
}
@media (min-width: 1080px) {
  .esg-line__row-column .item-content__list-row:nth-child(1) {
    margin-top: -50px;
  }
  .esg-line__row-column .item-content__list-row:nth-child(2) {
    margin-top: 50px;
    margin-left: -200px;
    width: 800px;
  }
  .esg-line__row-column .item-content__list-row:nth-child(3) {
    margin-top: 50px;
  }
}
.esg-line__row-column .item-content__list-row .column {
  @apply w-1/2;
}
.esg-line__row-column .item-content__list-row .column-item {
  @apply mx-auto rounded-full overflow-hidden;
  width: 100px;
  height: 100px;
}
@media (min-width: 1080px) {
  .esg-line__row-column .item-content__list-row .column-item {
    width: 125px;
    height: 125px;
  }
}
.esg-line__row-column .item-content__list-row .column-item__header {
  @apply p-4 prose-headings:text-xs lg:prose-headings:text-sm;
}

.career-search__form .search-input {
  @apply pr-12 !important;
}
.career-search__form .search-submit {
  @apply w-12 h-full;
}
@media (min-width: 1080px) {
  .career-search__form .category {
    @apply flex;
  }
  .career-search__form .category-field {
    @apply flex-1 pr-4;
  }
  .career-search__form .category-submit {
    @apply w-[150px];
  }
}
@media (min-width: 1080px) {
  .career-banner {
    @apply py-0;
    height: 400px;
  }
  .career-banner__body {
    @apply flex items-center;
  }
}
.career-banner__quote h2 {
  @apply relative;
}
.career-banner__quote h2::before, .career-banner__quote h2::after {
  content: '"';
  @apply relative inline-block;
}

@media (min-width: 1080px) {
  .contact-front__maps {
    height: 600px;
  }
}
.contact-front__maps::before, .contact-front__maps::after {
  content: "";
  @apply block;
}
.contact-front__maps::before {
  padding-top: 50%;
}
.contact-front__maps::after {
  @apply clear-both;
}
.contact-front__maps > * {
  @apply absolute top-0 left-0 h-full w-full;
}
@media (min-width: 1080px) {
  .contact-front__list {
    margin-top: -12.5%;
  }
}

.services-problem__solution {
  @apply mt-10 py-10;
}
@media (min-width: 1080px) {
  .services-problem__solution {
    @apply mt-16 py-16;
  }
}
.services-problem__solution-list .item {
  @apply relative;
  padding-bottom: 64px;
}
@media (min-width: 1080px) {
  .services-problem__solution-list .item {
    padding-bottom: 128px;
  }
  .services-problem__solution-list .item::before {
    content: "";
    @apply bg-primary absolute bottom-8 left-1/2 h-16 -translate-x-1/2;
    width: 1px;
  }
}
.services-problem__solution-list .item:last-child {
  @apply pb-0;
}
.services-problem__solution-list .item:last-child::before {
  @apply hidden;
}
.services-problem__description {
  @apply mb-10;
}
@media (min-width: 1080px) {
  .services-problem__description {
    @apply mb-16;
  }
}
.services-testi__list .item-image {
  height: 215px;
}
.services-testi__list .item-content {
  @apply absolute bottom-4 left-4 right-4 prose-headings:text-primary;
}
@media (min-width: 1080px) {
  .services-sub__hero-content .title {
    @apply prose-headings:text-primary prose-headings:text-8xl;
  }
}
.services-sub__experience-title {
  @apply mb-8;
}
@media (min-width: 1080px) {
  .services-sub__experience-title {
    @apply flex items-end mb-14 prose-headings:text-8xl;
  }
  .services-sub__experience-title .actions {
    @apply pl-20 prose-p:text-1xl;
  }
}
.services-sub__experience-point .item {
  @apply w-full;
}
@media (min-width: 1080px) {
  .services-sub__experience-point .item {
    @apply w-1/3;
  }
  .services-sub__experience-point .item-title {
    @apply mb-8;
  }
}
.services-sub__experience-point .item-title {
  @apply relative;
}
.services-sub__experience-point .item-title::before {
  content: "";
  @apply absolute -bottom-4 bg-warning w-18;
  height: 2px;
}
.services-sub__case .item:nth-of-type(even) .item-image {
  @apply rounded-tr-none rounded-tl-[200px] order-2;
}
.services-sub__case .item-image {
  @apply rounded-tr-[200px] overflow-hidden prose-img:w-full;
}
.services-sub__case .item-content__title {
  @apply mb-5;
}

@media (min-width: 1080px) {
  .cold-point {
    padding-bottom: 175px;
  }
}
@media (min-width: 1080px) {
  .cold-store__title {
    @apply prose-headings:text-6xl;
  }
}
.cold-flow__title-text {
  @apply prose-headings:mb-4 prose-headings:text-primary;
}
@media (min-width: 1080px) {
  .cold-flow__title-text {
    @apply prose-headings:text-6xl;
  }
}
.cold-flow__title-list {
  counter-reset: item-counter;
}
.cold-flow__title-list .item-marker {
  @apply flex items-center border-b border-b-[#EFEFEF];
}
.cold-flow__title-list .item-marker::before {
  @apply inline-flex items-center justify-center mr-4 font-bold text-primary bg-[#EFEFEF];
  counter-increment: item-counter;
  content: counter(item-counter);
}
@media (min-width: 1080px) {
  .cold-flow__title-list .item-marker::before {
    @apply w-14 h-14 text-4xl;
  }
}
.cold-flow__thumb {
  @apply lg:absolute 3xl:relative 3xl:w-full !important;
}
@media (min-width: 1080px) {
  .cold-flow__thumb {
    @apply bottom-16 right-0;
    width: calc(50% - 40px);
  }
  .cold-flow__thumb img {
    @apply w-full;
  }
}
@media (min-width: 1080px) {
  .cold-app__row {
    @apply px-16 py-12;
  }
}
.cold-app__title {
  @apply flex-1;
}
@media (min-width: 1080px) {
  .cold-app__title {
    @apply w-2/3 pr-10;
  }
  .cold-app__title-text {
    @apply prose-headings:text-3xl;
  }
}
@media (min-width: 1080px) {
  .cold-app__thumb {
    @apply absolute right-0 bottom-0;
  }
}

.mobility-point__title {
  @apply mb-8 prose-headings:text-primary;
}
@media (min-width: 1080px) {
  .mobility-point__title {
    @apply mb-14 prose-headings:text-8xl;
  }
}
.mobility-flow__title {
  @apply prose-headings:mb-6;
}
@media (min-width: 1080px) {
  .mobility-flow__title {
    @apply prose-headings:text-8xl;
  }
}
@media (max-width: 767.98px) {
  .mobility-maps__row .left {
    @apply order-2;
  }
}
.mobility-maps__row .left-list {
  @apply overflow-y-auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 400px;
}
@media (min-width: 1080px) {
  .mobility-maps__row .left-list {
    @apply w-2/5 mr-8;
    height: 650px;
  }
}
.mobility-maps__row .left-list::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.mobility-maps__row .left-list .media {
  @apply mb-6 pb-6 border-b border-b-grayMedium;
}
@media (max-width: 767.98px) {
  .mobility-maps__row .right {
    @apply mb-8 order-1;
  }
}
@media (min-width: 1080px) {
  .mobility-maps__row .right {
    @apply absolute top-0 right-0 w-[55%];
    height: 650px;
  }
}

.services-jumbotron__list .item:nth-of-type(odd) .item-image img {
  @apply rounded-tl-[200px];
}
.services-jumbotron__list .item:nth-of-type(even) .item-image img {
  @apply rounded-tr-[200px];
}
@media (min-width: 1080px) {
  .services-jumbotron__list .item:nth-of-type(even) .item-content {
    @apply order-2;
  }
}
.services-jumbotron__list .item-content {
  @apply prose-headings:text-primary;
}
@media (min-width: 1080px) {
  .services-jumbotron__list .item-content {
    @apply w-[55%] !important;
  }
  .services-jumbotron__list .item-content__title {
    @apply mb-4 prose-headings:text-4xl;
  }
}
@media (min-width: 1080px) {
  .services-jumbotron__list .item-image {
    @apply w-[45%] !important;
  }
}
.services-video__dialog {
  @apply relative aspect-[8/3.5];
}
@media (min-width: 1080px) {
  .services-video__dialog::before {
    content: "";
    @apply absolute top-0 right-0 left-0 bottom-0 bg-black bg-opacity-60;
  }
}
@media (max-width: 1079.98px) {
  .services-video__content {
    @apply py-8;
  }
}
.services-video__content-text .title {
  @apply mb-8;
}
.services-video__content-text .point-list {
  @apply mb-8;
}
@media (min-width: 1080px) {
  .services-video__content-text .title {
    @apply mb-14 prose-headings:text-5xl prose-headings:mx-10;
  }
  .services-video__content-text .point-list {
    @apply mb-14;
  }
}
.services-video__content-text .point-list__item-content {
  @apply mt-2;
}
@media (min-width: 1080px) {
  .services-video__content-text .point-list__item-content {
    @apply prose-headings:mb-2 prose-headings:text-6xl prose-headings:italic prose-p:text-1xl prose-p:font-bold;
  }
}
.services-video__content-text .point-action .btn {
  @apply border-primary;
}
.services-video__content-text .point-action .btn:hover {
  @apply bg-primary text-white;
}
@media (min-width: 1080px) {
  .services-video__content-text .point-action .btn {
    @apply border-white;
  }
  .services-video__content-text .point-action .btn:hover {
    @apply bg-white text-primary;
  }
}