@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.dropdown {

    &-toggle {
        @apply inline-flex items-center;

        .dropdown--navbar & {
            @apply after:hidden;

            .navbar-front & {

                svg {
                    @apply fill-white;
                }
            }

            &.show {
                @apply text-warning border-b-warning;

                svg {
                    @apply -rotate-180 fill-warning;
                }
            }
        }

        .arrow {
            @apply ml-2;
        }
    }

    &-menu {
        @apply bg-white p-6 shadow rounded-none;

        @include media-breakpoint-up(lg) {
            min-width: 300px;
        }

        .dropdown--navbar & {
            @apply mt-0;

            &__list {

                a {
                    @apply hover:text-warning;
                }
            }
        }

        .dropdown--lang & {

            &__list {

                img {
                    @apply h-4;
                }
            }
        }
    }
}