.career {

    &-search {

        &__form {

            .search {

                &-input {
                    @apply pr-12 #{!important};
                }

                &-submit {
                    @apply w-12 h-full;
                }
            }

            .category {

                @include media-breakpoint-up(lg) {
                    @apply flex;

                    &-field {
                        @apply flex-1 pr-4;
                    }

                    &-submit {
                        @apply w-[150px];
                    }
                }
            }
        }
    }

    &-banner {

        @include media-breakpoint-up(lg) {
            @apply py-0;
            height: 400px;

            &__body {
                @apply flex items-center;
            }
        }

        &__quote {

            h2 {
                @apply relative;

                &::before, &::after {
                    content: '"';
                    @apply relative inline-block;
                }
            }
        }
    }
}