.post {

    &-title {

        &__tag {

            .tag {
                @apply bg-warning py-1 px-3 uppercase;

                @include media-breakpoint-up(lg) {
                    @apply py-2 px-4;
                }
            }

            .date {

                &-icon {

                    svg {
                        @apply h-4 fill-grayDark;
                    }
                }
            }
        }

        &__loc {

            > div {

                + div {
                    
                    &::before {
                        content: '|';
                        @apply relative inline-block px-2 text-gray;
                    }
                }
            }
        }
    }

    &-detail {
        @apply prose-headings:text-primary prose-headings:mb-2 prose-p:mb-5 prose-a:text-secondary prose-a:font-bold prose-ul:mb-5 prose-ol:mb-5 prose-ul:pl-4 prose-ol:pl-4 prose-ul:list-disc prose-ol:list-decimal;

        p {

            &:last-child {
                margin-bottom: 0 #{!important};
            }
        }

        &--tms {

            ul {
                @apply pl-0;

                li {
                    @apply inline-flex items-center font-bold text-primary;

                    &::before {
                        content: '';
                        @apply relative inline-block bg-contain w-8 h-8 mr-4 bg-no-repeat;
                        background-image: url(../images/check2-circle.png);
                    }

                    + li {
                        @apply mt-4;
                    }
                }
            }
        }
    }
}