.nav {

    &-solved {

        &__header {

            @include media-breakpoint-up(lg) {
                @apply grid gap-4 overflow-y-scroll;
                height: 550px;

                &-item {
                    @apply py-4 px-6;
                }
            }

            &-item {
                @apply flex items-center border border-[#AEAEAE] transition ease-in-out;

                &.active {
                    @apply border-2 border-primary;

                    &:hover {
                        @apply border-primary prose-headings:text-primary;
                    }
                }

                &:hover {
                    @apply border-2 border-secondary prose-headings:text-secondary;
                }

                .image {

                    @include media-breakpoint-up(lg) {
                        @apply prose-img:w-22;
                    }
                }

                .title {
                    @apply flex-1;

                    @include media-breakpoint-up(lg) {
                        @apply pl-4;
                    }
                }
            }
        }

        &__content {

            &-item {

                @include media-breakpoint-up(lg) {
                    @apply p-10;
                }

                .item {

                    &-row {

                        @include media-breakpoint-up(lg) {
                            @apply flex flex-wrap -m-6;
                        }

                        &__title, &__thumb {
                            @apply w-full p-6;
                        }

                        &__title {

                            @include media-breakpoint-up(lg) {
                                @apply w-2/5;
                            }
                        }

                        &__thumb {
                            @apply mix-blend-multiply;

                            @include media-breakpoint-up(lg) {
                                @apply w-3/5;
                            }
                        }
                    }
                }
            }
        }
    }
}