.esg {

    &-line {
        @apply py-16;
    
        @include media-breakpoint-up(lg) {
            @apply py-24;
        }

        &__title {

            @include media-breakpoint-down(lg) {
                @apply mb-16;
            }

            @include media-breakpoint-up(lg) {
                @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-2 border-dashed border-primary rounded-full prose-headings:text-5xl;
                width: 600px;
                height: 600px;
            }
        }

        &__row {
            @apply -my-16;

                &-column {
                    @apply py-16;
                }

            @include media-breakpoint-up(lg) {
                @apply -my-[120px];

                &-column {
                    @apply py-[120px];
                }
            }

            &-column {

                &:nth-child(1) {
                    @apply w-full;
                }

                &:nth-child(2), &:nth-child(3) {
                    @apply w-full;

                    @include media-breakpoint-up(lg) {
                        @apply w-1/2;
                    }
                }

                .item {
                    @apply mx-auto rounded-full;
                    width: 300px;
                    height: 300px;

                    &:hover {
                        
                        .item {

                            &-image {

                                img {
                                    @apply scale-110;
                                }
                            }

                            &-content {

                                &__header {
                                    width: 300px;
                                    height: 300px;

                                    @include media-breakpoint-up(lg) {
                                        width: 400px;
                                        height: 400px;
                                    }
                                }

                                &__list {
                                    @apply opacity-100;
                                }
                            }
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        width: 400px;
                        height: 400px;
                    }

                    &-image {
                        @apply rounded-full overflow-hidden;

                        img {
                            transition: transform .5s ease-in-out;
                        }
                    }

                    &-content {

                        &__header {
                            @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex-col bg-white bg-opacity-50 rounded-full overflow-hidden prose-img:w-8 prose-img:mb-1;
                            width: 150px;
                            height: 150px;
                            backdrop-filter: blur(5px);
                            transition: width .5s, height .5s;

                            @include media-breakpoint-up(lg) {
                                width: 175px;
                                height: 175px;
                            }
                        }

                        &__list {
                            @apply absolute top-0 w-full h-full z-10;
                            transition: opacity .5s;

                            @include media-breakpoint-up(lg) {
                                @apply opacity-0;
                            }

                            &-row {

                                &:nth-child(1) {
                                    margin-top: -40px;
                                }

                                &:nth-child(2) {
                                    margin-top: 40px;
                                    margin-left: -125px;
                                    width: calc(100% + 250px);
                                }

                                &:nth-child(3) {
                                    margin-top: 40px;
                                }

                                @include media-breakpoint-up(lg) {

                                    &:nth-child(1) {
                                        margin-top: -50px;
                                    }

                                    &:nth-child(2) {
                                        margin-top: 50px;
                                        margin-left: -200px;
                                        width: calc(400px + 400px);
                                    }

                                    &:nth-child(3) {
                                        margin-top: 50px;
                                    }
                                }

                                .column {
                                    @apply w-1/2;

                                    &-item {
                                        @apply mx-auto rounded-full overflow-hidden;
                                        width: 100px;
                                        height: 100px;

                                        @include media-breakpoint-up(lg) {
                                            width: 125px;
                                            height: 125px;
                                        }

                                        &__header {
                                            @apply p-4 prose-headings:text-xs lg:prose-headings:text-sm;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}