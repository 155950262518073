.modal {
    --bs-modal-border-radius: 0;

    &-backdrop {
        --bs-backdrop-opacity: .75;
    }

    &-close {
        @apply absolute -top-10 right-0;
    }

    &-dialog {

        .modal--concern &, .modal--management &, .modal--certify & {

            @include media-breakpoint-up(lg) {
                max-width: 900px;
            }
        }
    }

    &-body {

        .modal--concern &, .modal--management & {

            &__content {
                height: calc(100vh - 250px);
                overflow-y: scroll;
            }
        }

        .modal--concern & {
            @apply p-4 lg:p-6;
        }

        .modal--management & {
            @apply p-4 lg:p-14;

            &__content {

                .content {
                    @apply pt-6;
                }

                @include media-breakpoint-up(lg) {
                    @apply flex;

                    .thumbnail {
                        @apply sticky top-0 prose-img:w-full;
                        width: 300px;
                    }

                    .content {
                        @apply flex-1 pl-10 pt-0;

                        &-title {
                            @apply prose-headings:mb-2 prose-headings:text-5xl;
                        }
                    }
                }
            }
        }

        .modal--certify & {
            
            @include media-breakpoint-up(lg) {
                @apply py-10 px-14;
            }
        }
    }
}