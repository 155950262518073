.maps {

    &-title {
        @apply bg-white mx-auto m-4 p-4 overflow-hidden prose-headings:text-primary;
        box-shadow: 0px 10px 30px rgba($color: #000000, $alpha: .1);

        &__form {
            @apply mt-4;
        }

        &__input {
            padding-right: 50px #{!important};
        }

        &__submit {
            @apply top-1/2 -translate-y-1/2 flex items-center justify-center w-8 h-8 rounded-full overflow-hidden;
        }

        @include media-breakpoint-up(lg) {
            @apply flex items-center w-[900px] px-12 py-8 prose-headings:text-6xl;

            &__form {
                @apply mt-0 pl-8;
            }
        }
    }

    &-content {

        .map {
            @apply bg-transparent;

            .leaflet {

                &-popup {

                    &-content {
                        @apply text-sm prose-headings:text-primary prose-p:m-0;
                        max-width: 190px;

                        &-wrapper {
                            @apply shadow-lg rounded-none;
                        }

                        .item {

                            &-header {
                                @apply mb-4 prose-headings:uppercase prose-headings:mb-1 prose-a:text-black hover:prose-a:text-primary;

                                &__image {
                                    @apply mb-2 aspect-video overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
                                }
                            }

                            &-content {
                                @apply grid gap-2 prose-headings:text-sm;
                            }
                        }
                    }

                    &-tip {
                        @apply shadow-lg;
                    }
                }

                &-pane {
                    @apply z-10;
                }
            }

            .mapTooltip {
                @apply bg-white absolute p-2 rounded-sm shadow-md overflow-hidden z-30;
            }

            .zoomButton {
                background-color: #fff;
                border: 1px solid #ccc;
                color: #000;
                width: 32px;
                height: 32px;
                text-align: center;
                cursor: pointer;
                position: absolute;
                bottom: 0;
                font-weight: bold;
                left: 10px;
                user-select: none;
            }
    
            /* Reset Zoom button first */
            .zoomReset {
                bottom: calc(12px + 32px + 32px + 12px + 8px);
            }
    
            /* Then Zoom In button */
            .zoomIn {
                bottom: calc(12px + 32px + 8px);
            }
    
            /* Then Zoom Out button */
            .zoomOut {
                bottom: 12px;
            }

            &-tooltip {
                @apply flex items-center;

                p {
                    @apply pl-2;
                }

                img {
                    @apply h-3;
                }
            }

            .marker {
                @apply inline-flex items-center justify-center text-white text-xs rounded-full;

                &-green {
                    @apply w-5 h-5 bg-[#017D37];
                }

                &-pink {
                    @apply w-7 h-7 bg-[#AE0A88];
                }

                &-teal {
                    @apply w-9 h-9 bg-[#009187];
                }

                &-red {
                    @apply w-11 h-11 bg-[#F9104F];
                }
            }
        }
    }
}