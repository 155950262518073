.table {

    thead {

        th {
            @apply px-2 pb-4 border-0 font-bold align-bottom;
            text-align: inherit;
            text-align: -webkit-match-parent;

            @include media-breakpoint-up(lg) {
                @apply text-1xl;

                &:nth-child(3) {
                    width: 300px;
                }

                &:nth-child(4) {
                    width: 24px;
                }
            }
        }
    }

    tbody {

        td {
            @apply px-2 py-4 align-top border-b border-dashed border-gray;

            @include media-breakpoint-up(lg) {

                &:nth-child(1) {
                    @apply font-bold text-primary;
                }

                &:nth-child(3) {
                    width: 300px;
                }

                &:nth-child(4) {
                    @apply align-middle;
                    width: 24px;
                }
            }
        }
    }
}