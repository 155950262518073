.section {
    @apply py-10;

    @include media-breakpoint-up(lg) {
        @apply py-16;
    }

    &-title {
        @apply mb-8 prose-headings:text-2xl prose-headings:text-primary;

        @include media-breakpoint-up(lg) {
            @apply mb-14 prose-headings:text-8xl mx-auto;
        }

        .home-solution & {
            @apply pt-12 text-center;

            .actions {
                @apply mt-4 mx-auto;

                @include media-breakpoint-up(lg) {
                    max-width: 850px;
                }
            }
        }

        .home-event &, .cold-news & {
            @apply flex items-center;

            .actions {
                @apply ml-auto;
            }
        }

        .home-client & {

            @include media-breakpoint-up(lg) {
                max-width: 850px;
            }
        }

        .future-about &, .reward-intro &, .about-milestone &, .services-testi &, .investor-group &, .contact-form &, .career-result & {

            .actions {
                @apply mt-4 mx-auto;

                @include media-breakpoint-up(lg) {
                    max-width: 800px;
                }
            }
        }

        .future-about &, .reward-intro & {

            @include media-breakpoint-up(lg) {
                
                .actions {
                    max-width: 800px;
                }
            }
        }

        .about-group & {

            @include media-breakpoint-up(lg) {
                @apply prose-headings:text-2xl;
            }

            .actions {
                @apply mt-6;
            }
        }

        .action-line & {

            @include media-breakpoint-up(lg) {
                @apply w-3/5 prose-headings:text-5xl;

                &::before {
                    content: '';
                    @apply absolute -bottom-4 right-0 left-0 mx-auto bg-primary;
                    width: 125px;
                    height: 2px;
                }
            }
        }

        .services-problem__solution & {
            @apply mb-0;
            padding-bottom: 64px;

            @include media-breakpoint-up(lg) {
                padding-bottom: 64px + 32px + 32px;

                &::before {
                    content: '';
                    @apply bg-primary absolute bottom-8 left-1/2 h-16 -translate-x-1/2;
                    width: 1px;
                }
            }
        }

        .services-choose & {

            &__group {
                @apply flex items-end;

                @include media-breakpoint-up(lg) {

                    &-text {
                        @apply mb-0 pr-10;
                    }
                }
            }
        }

        .services-price & {

            .actions {
                @apply mt-4 prose-p:font-bold;

                @include media-breakpoint-up(lg) {
                    @apply prose-p:text-2xl;
                }
            }
        }

        .career-result & {

            .actions {

                @include media-breakpoint-up(lg) {
                    @apply prose-p:text-1xl;
                }
            }
        }
        
        .services-coverage & {
            
            @include media-breakpoint-up(lg) {
                @apply flex prose-h2:w-[275px];

                .actions {
                    @apply flex-1 pl-10;
                }
            }
        }
    }

    &-last {
        padding-bottom: calc(80px + 40px);

        @include media-breakpoint-up(lg) {
           padding-bottom: calc(100px + 32px);
        }
    }
}