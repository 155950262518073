.media {

    &--news {

        &:hover {

            .media {

                &-image {

                    img {
                        @apply scale-110;
                    }
                }

                &-content {

                    &__title {
                        @apply prose-headings:text-primary;
                    }
                }
            }
        }

        &-big {
            @apply items-center;

            @include media-breakpoint-up(lg) {
                @apply col-span-2;
            }
        }
    }

    &--future {

        @include media-breakpoint-up(lg) {
            @apply flex items-center;
        }

        &:nth-of-type(odd) {

            .media {

                &-image {
                    @apply rounded-tl-[200px];
                }
            }
        }
        
        &:nth-of-type(even) {

            .media {

                &-image {
                    @apply rounded-br-[200px];
                    
                    @include media-breakpoint-up(lg) {
                        @apply order-2;
                    }
                }

                &-content {

                    @include media-breakpoint-up(lg) {
                        @apply pl-0 pr-10;
                    }
                }
            }
        }

        &-opt {
            @apply mt-10;

            @include media-breakpoint-up(lg) {
                @apply mt-16 flex items-center;
            }
        }
    }

    &--problem {
        @apply bg-white flex items-center p-4 overflow-hidden;

        @include media-breakpoint-up(lg) {
            @apply py-6 px-10;
        }
    }

    &--line {
        @apply w-full;

        @include media-breakpoint-up(lg) {
            @apply w-1/2;
        }
    }

    &--solved {
        @apply flex;

        @include media-breakpoint-up(lg) {
            @apply py-6 px-7;
        }

        &:hover {
            box-shadow: 0 8px 24px rgba($color: #000000, $alpha: .05);
        }
    }

    &-dialog {

        .media--line & {
            @apply bg-white w-full;
            box-shadow: 0 4px 24px rgba($color: #000000, $alpha: .1);

            @include media-breakpoint-up(lg) {
                width: 400px;
            }
        }
    }

    &-image {

        .media--news & {
            @apply aspect-square overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;

            @include media-breakpoint-down(lg) {
                width: 125px;
            }

            @include media-breakpoint-up(lg) {
                @apply aspect-video;
            }

            img {
                transition: transform .5s ease-in-out;
            }
        }

        .media--news-big & {

            @include media-breakpoint-up(lg) {
                width: calc(50% - 16px);
            }
        }

        .media--news-small & {

            @include media-breakpoint-up(lg) {
                height: 150px;
            }
        }

        .media--future & {
            @apply prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;

            @include media-breakpoint-up(lg) {
                @apply w-1/2;
            }
        }

        .media--problem & {
            @apply w-8 prose-img:w-8 lg:w-12 lg:prose-img:w-12;
        }

        .media--solved & {

            &::before {
                content: '';
                @apply absolute right-0 left-0 -bottom-4 bg-primary;
                height: 2px;
            }
            
            @include media-breakpoint-up(lg) {
                @apply w-18 h-fit prose-img:h-12 prose-img:mx-auto;
            }
        }

        .media--mobility & {
            width: 120px;
        }
    }

    &-content {

        .media--news & {
            @apply pl-4;

            &__tag {

                .tag {
                    @apply bg-warning py-1 px-3 uppercase;

                    @include media-breakpoint-up(lg) {
                        @apply py-2 px-4;
                    }
                }

                .date {

                    &-icon {

                        svg {
                            @apply h-4 fill-grayDark;
                        }
                    }
                }
            }

            &__title {
                @apply prose-headings:text-rg prose-headings:line-clamp-2;
            }
        }

        .media--news-big & {

            @include media-breakpoint-up(lg) {
                @apply pl-10;
            }

            &__title {
                @apply prose-p:hidden;

                @include media-breakpoint-up(lg) {
                    @apply mt-8 mb-12 prose-headings:text-4xl prose-p:block prose-headings:h-20 prose-p:mt-4 prose-p:line-clamp-3 prose-p:h-18;
                }
            }
        }

        .media--news-small & {

            @include media-breakpoint-up(lg) {
                @apply pl-6;
            }

            &__title {

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-1xl prose-headings:h-[50px];
                }
            }
        }

        .media--future & {
            @apply flex-1 pt-6;
            
            @include media-breakpoint-up(lg) {
                @apply pl-10 pt-0;
            }

            &__title {

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-5xl;
                }
            }
        }

        .media--future-opt & {
            @apply flex-1 pb-6;

            @include media-breakpoint-up(lg) {
                @apply pr-10 pb-0;
            }

            &__title {

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-5xl;
                }
            }
        }

        .media--problem & {
            @apply flex pl-4 text-primary;

            @include media-breakpoint-up(lg) {
                @apply prose-headings:text-1xl;
            }
        }

        .media--solved & {
            @apply flex-1;

            @include media-breakpoint-up(lg) {
                @apply pl-6;
            }
        }

        .media--mobility & {
            @apply pl-4;
            
            @include media-breakpoint-up(lg) {
                @apply pl-8;
            }
        }
    }
}