$root-variables: (
    // primary color
    primary-color: #252B5B,
    primary-color-invert: #{lighten(#252B5B, 100%)},
    primary-color-hover: #ECCE18,

    // secondary color
    secondary-color: #C0942F,
    secondary-color-invert: #{lighten(#C0942F, 100%)},
    secondary-color-hover: #ECCE18,

    // light color
    light-color: #fff,
    light-color-invert: #{darken(#fff, 100%)},
    light-color-hover: #ECCE18,

    // button regular
    button-padding-y: 16px,
    button-padding-x: 40px,
    button-font-size: 1rem,
    button-rounded: 0,

    // button small
    button-sm-padding-y: 4px,
    button-sm-padding-x: 8px,
    button-sm-font-size: .875rem,

    // button large
    button-lg-padding-y: 12px,
    button-lg-padding-x: 16px,
    button-lg-font-size: 1.25rem,
    
    // button form
    button-forms-padding-y: 12px,
    button-forms-padding-x: 32px,
    button-forms-font-size: 1em,
);

.btn {
    @each $key, $value in $root-variables {
        --#{$key}: #{$value};
    }

    @apply relative inline-flex items-center justify-center text-center px-4 py-3 border border-transparent font-bold;
    font-size: var(--button-font-size);
    border-radius: var(--button-rounded);
    outline: 0;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    @include media-breakpoint-up(lg) {
        padding: var(--button-padding-y) var(--button-padding-x);
    }

    &-block {
        @apply relative inline-flex items-center;

        &__icon {
            @apply mr-2;

            svg {
                @apply h-4;
            }
        }
    }

    &-primary {
            
        &::before {
            content: '';
            @apply bg-opacity-60 absolute top-4 right-3 left-3 h-12;
            filter: blur(16px);
            background-color: var(--primary-color) !important;
        }
    }

    @each $sizes in sm, lg, forms {
        $button-y-size: var(--button-#{$sizes}-padding-y);
        $button-x-size: var(--button-#{$sizes}-padding-x);
        $button-font-size: var(--button-#{$sizes}-font-size);

        &-#{$sizes} {
            padding: $button-y-size $button-x-size;
            font-size: $button-font-size;
        }
    }
    
    @each $colors in primary, secondary, light {
        $button-color: var(--#{$colors}-color);
        $button-color-invert: var(--#{$colors}-color-invert);

        &-#{$colors} {
            background-color: $button-color !important;
            color: $button-color-invert;

            svg {
                fill: var(--#{$colors}-color-invert);
            }

            &:hover {
                @apply text-primary;
                background-color: var(--#{$colors}-color-hover) !important;

                svg {
                    @apply fill-primary;
                }

                &::before {
                    background-color: var(--#{$colors}-color-hover) !important;
                }
            }
        }

        &-outline-#{$colors} {
            border-color: $button-color;
            color: $button-color;

            svg {
                fill: $button-color;
            }

            &:hover {
                color: var(--#{$colors}-color-invert);
                background-color: $button-color !important;

                svg {
                    fill: var(--#{$colors}-color-invert);
                }
            }
        }
    }
}