.home {

    &-banner {

        @include media-breakpoint-only(md) {
            padding-bottom: 130px;
        }

        @include media-breakpoint-down(md) {
            @apply pb-24;
        }
    }

    &-solution {

        &::before {
            content: '';
            @apply bg-primary absolute w-full;
            height: 200px;
            mask-image: url(../images/wave.png);
            mask-position: center top;
            margin-top: -150px;
            mask-repeat: no-repeat;
            mask-size: cover;

            @include media-breakpoint-only(md) {
                margin-top: -170px;
            }
        }
    }

    &-action {

        &__body {

            &-coverage {
                @apply p-6;

                @include media-breakpoint-up(lg) {
                    @apply py-8 px-16;
                }

                .content {
                    @apply mb-6 prose-headings:mb-4;
                }
            }

            &-call {
                @apply bg-contain ;
                background-position: right top;
                
                @include media-breakpoint-up(lg) {
                    background-position: left center;
                }

                .content {
                    @apply pt-22 pb-14;
                    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(37,43,91,1) 25%);

                    @include media-breakpoint-up(lg) {
                        @apply py-14;
                        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(37,43,91,1) 150%);
                    }

                    &-block {
                        @apply ml-auto px-10 prose-headings:mb-8;

                        @include media-breakpoint-up(lg) {
                            @apply w-3/5 pl-12 pr-22;
                        }
                    }
                }
            }
        }
    }

    &-about {

        &__image {

            @include media-breakpoint-up(lg) {
                @apply w-2/3 #{!important};
            }

            &-play {
                @apply bg-primary inline-flex items-center justify-center top-1/2 left-1/2 w-18 h-18 rounded-full overflow-hidden;
                transform: translate(-50%, -50%);
                transition: transform .5s ease-in-out;
                
                svg {
                    @apply h-6;
                }

                &:hover {
                    @apply bg-warning;

                    svg {
                        @apply fill-primary;
                    }
                }
            }
        }

        &__content {

            @include media-breakpoint-up(lg) {
                @apply w-1/3 #{!important};
            }

            &-title {
                @apply mb-4;

                @include media-breakpoint-up(lg) {
                    @apply mb-6;
                }
            }
        }
    }

    &-client {

        &__body {

            &-list {
                @apply mb-6;

                &:last-child {
                    @apply mb-0 pb-0 border-b-0;
                }

                @include media-breakpoint-up(lg) {
                    @apply mb-12 pb-12;
                }

                .title {
                    @apply flex items-center justify-center mb-6 border-b border-b-grayMedium prose-headings:bg-grayMedium prose-headings:py-2 prose-headings:px-4 prose-headings:text-1xl prose-headings:text-primary;

                    @include media-breakpoint-up(lg) {
                        @apply mb-8 prose-headings:text-5xl;
                    }
                }
            }
        }
    }
}