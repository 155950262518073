.about {

    &-intro {

        &__started {
            @apply py-10;

            @include media-breakpoint-up(lg) {
                @apply py-16;
            }

            &-image {

                @include media-breakpoint-up(lg) {
                    @apply absolute left-0 prose-img:mt-12;
                    width: calc(50% - 32px);

                    &:before {
                        content: '';
                        @apply absolute bg-primary top-0 left-0;
                        width: calc(100% - 48px);
                        height: calc(100% + (32px * 1.5));
                    }
                }
            }

            &-text {

                @include media-breakpoint-up(lg) {
                    @apply ml-auto w-1/2 mt-12;
                }
            }
        }

        &__vision {
            @apply relative mt-10;

            @include media-breakpoint-up(lg) {
                @apply mt-16;

                &::before {
                    content: '';
                    @apply absolute bg-primary top-0 left-1/2 h-full -translate-x-1/2;
                    width: 1px;
                }
            }
        }
    }

    &-group {

        &__list {

            &-title {

                &::before {
                    content: '';
                    @apply absolute top-1/2 left-0 right-0 bg-primary;
                    height: 1px;
                }

                > h6 {
                    @apply relative inline-block px-8 bg-grayLight;
                }
            }

            &-content {

                .item {
                    @apply flex items-center justify-center bg-white cursor-pointer border border-transparent overflow-hidden transition ease-in-out prose-img:h-10;
                    height: 135px;
                    border-radius: 50%;

                    img {
                        transition: transform .5s ease-in-out;
                    }

                    &:hover {
                        @apply border-warning;

                        img {
                            @apply scale-110;
                        }
                    }
                }
            }
        }
    }

    &-jumbotron {
        height: 300px;

        @include media-breakpoint-up(lg) {
            height: 460px;
        }

        &::before, &::after {
            content: '';
            @apply absolute right-0 left-0 h-full;
        }

        &::before {
            @apply top-0;
            background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 65%);
        }

        &::after {
            @apply bottom-0;
            background: linear-gradient(0, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 65%);
        }

        &__quote {
            @apply text-primary font-bold italic leading-tight prose-p:text-4xl;

            @include media-breakpoint-up(lg) {
                @apply w-[740px] prose-p:text-8xl;
            }

            p {
                @apply relative;

                &::before, &::after {
                    content: '';
                    @apply relative inline-block bg-no-repeat bg-contain w-8 h-8;
                }

                &::before {
                    @apply -top-4;
                    background-image: url(../images/icon-quote-left-solid.png);
                }

                &::after {
                    @apply -bottom-4;
                    background-image: url(../images/icon-quote-right-solid.png);
                }
            }
        }
    }

    &-milestone {

        &__parent {
            padding-top: 40px+32px;
            padding-bottom: 40px+32px;

            @include media-breakpoint-up(lg) {
                padding-top: 80px+32px;
                padding-bottom: 80px+32px;
            }

            &::before, &::after {
                content: '';
                @apply bg-white absolute left-0 right-0 h-2;
            }

            &::before {
                @apply top-8;
            }

            &::after {
                @apply bottom-8;
            }
        }

        &__thumb {

            @include media-breakpoint-up(lg) {

                &::before {
                    content: '';
                    @apply absolute bottom-0 right-0 w-1/2 h-1 bg-primary;
                }
            }

            &-flex {

                .slick {

                    &-content {

                        @include media-breakpoint-down(lg) {
                            @apply border-b-4 border-b-primary;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    @apply flex items-center border-b-4 border-b-primary;

                    .slick {
    
                        &-content {
                            @apply flex-1 w-full mr-6;
                        }
                    }
                }
            }
        }
    }
}