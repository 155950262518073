.tab {

    &-header {

        .tab--price & {

            @include media-breakpoint-down(lg) {
                @apply flex-nowrap whitespace-nowrap gap-2 overflow-x-scroll;
            }
    
            @include media-breakpoint-up(lg) {
                @apply -m-3;

                > div {
                    @apply p-3;
                }
            }
        }
    }

    &-link {
        @apply bg-white text-primary;

        &[aria-selected='true'] {
            @apply bg-primary border-primary text-warning;
        }
    }

    &-content {

        &__group {
            @apply mt-6;
            
            @include media-breakpoint-up(lg) {
                @apply mt-10;
            }

            .item {

                &-image {
                    
                    img {
                        @apply mx-auto;
                        height: 125px;
                    }
                }

                &-content {

                    &__price {

                        span {
                            @apply font-bold text-primary;

                            @include media-breakpoint-up(lg) {
                                @apply text-3xl;
                            }
                        }
                    }
                }
            }
        }
    }
}