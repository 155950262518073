.card {
    @apply overflow-hidden;
    transition: transform .5s ease-in-out;

    &--news {

        &:hover {

            .card {

                &-header {
                    
                    img {
                        @apply scale-110;
                    }
                }

                &-content {

                    &__title {
                        @apply prose-headings:text-primary;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            @apply flex items-center;
        }
    }

    &--certify {

        &:hover {

            .card {

                &-header {
                    
                    img {
                        @apply scale-110;
                    }
                }

                &-content {

                    &__title {
                        @apply prose-headings:text-secondary;
                    }
                }
            }
        }
    }

    &--solution {

        &:hover {

            .card {

                &-header {
                    @apply prose-headings:text-warning;
                }

                &-content {

                    &__image {

                        img {
                            @apply scale-110;
                        }
                    }

                    &__text {
                        @apply opacity-90;
                    }
                }
            }
        }
    }

    &--management {

        &:hover {

            .card {

                &-header {
                    
                    img {
                        @apply scale-110;
                    }
                }

                &-content {

                    &__actions {
                        @apply text-warning;

                        svg {
                            @apply fill-warning;
                        }
                    }
                }
            }
        }
    }

    &--vision {

        @include media-breakpoint-up(lg) {
            @apply py-10;
        }
    }

    &--provide {
        @apply p-4 border border-primary;

        @include media-breakpoint-up(lg) {
            @apply py-12 px-14;
        }

        &-2 {
            @apply overflow-hidden;

            @include media-breakpoint-up(lg) {
                height: 450px;
            }
        }
    }

    &--feature {
        @apply bg-white p-6 lg:py-8 overflow-hidden;
        box-shadow: 0px 8px 24px rgba($color: #000000, $alpha: .15);
    }

    &--serve {
        @apply bg-white overflow-hidden;
        box-shadow: 0px 8px 24px rgba($color: #000000, $alpha: .15);
    }

    &--location {
        @apply p-4;

        @include media-breakpoint-up(lg) {
            @apply p-6;
        }
    }

    &--cold {
        @apply p-4;
        box-shadow: 0 8px 24px rgba($color: #000000, $alpha: .15);

        @include media-breakpoint-up(lg) {
            @apply py-10 px-8;
        }
    }

    &-header {

        .card--news &, .card--certify & {

            img {
                transition: transform .5s ease-in-out;
            }
        }

        .card--news & {

            @include media-breakpoint-down(md) {
                @apply aspect-square overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
                width: 125px;
            }
        }

        .card--solution & {
            @apply mb-4 prose-headings:text-1xl;

            @include media-breakpoint-up(lg) {
                @apply mb-6;
            }
        }

        .card--management & {
            @apply aspect-square prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;

            img {
                transition: transform .5s ease-in-out;
            }
        }

        .card--value & {
            @apply mb-5;
        }

        .card--provide & {
            @apply mb-6;
        }

        .card--provide-2 & {
            @apply w-full h-full overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
        }

        .card--feature & {
            @apply mb-8 prose-headings:text-primary;
            
            &__icon {
                @apply w-15 prose-img:mx-auto prose-img:w-10;

                &::before {
                    content: '';
                    @apply absolute -bottom-4 right-0 left-0 bg-primary;
                    height: 2px;
                }
            }
        }

        .card--career & {
            @apply mx-auto aspect-square rounded-full overflow-hidden bg-gray prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
            width: 200px;
        }
    }

    &-content {

        .card--news & {

            @include media-breakpoint-down(md) {
                @apply flex-1 pl-4;
            }

            @include media-breakpoint-up(lg) {
                @apply mt-5;
            }

            &__tag {

                .tag {
                    @apply bg-warning py-1 px-3 uppercase;

                    @include media-breakpoint-up(lg) {
                        @apply py-2 px-4;
                    }
                }

                .date {

                    &-icon {

                        svg {
                            @apply h-4 fill-grayDark;
                        }
                    }
                }
            }

            &__title {
                @apply prose-headings:text-rg prose-headings:line-clamp-2;

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-1xl prose-headings:h-[50px];
                }
            }
        }

        .card--solution & {
            aspect-ratio: 4/3;

            &__image {
                @apply prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center overflow-hidden;
                aspect-ratio: inherit;

                img {
                    transition: transform .5s ease-in-out;
                }
            }

            &__text {
                @apply absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center opacity-0;
                transition: transform .5s ease-in-out;

                .dialog {
                    @apply bg-white flex flex-col items-center justify-between p-6 prose-p:text-black prose-p:line-clamp-4 prose-p:h-24;
                    width: calc(100% - 52px);
                    height: calc(100% - 48px);
                }
            }
        }

        .card--management & {

            &__title {
                @apply prose-p:font-bold;

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-2xl;
                }
            }
        }

        .card--provide & {

            &__title {
                @apply mb-3 text-primary;
            }
        }

        .card--provide-2 & {
            @apply absolute flex flex-col justify-end top-0 bottom-0 right-0 left-0 p-5;
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%);

            &__title {
                @apply mb-2 text-primary;

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-4xl;
                }
            }
        }

        .card--serve & {

            @include media-breakpoint-up(lg) {
                @apply -mt-10;
            }
        }

        .card--location & {

            &__actions {

                .item {
                    @apply inline-flex items-center text-primary hover:text-warning;

                    &-icon {

                        @include media-breakpoint-up(lg) {
                            @apply prose-img:h-5;
                        }
                    }
                }
            }
        }
    }
}